// Styled components for DynamicTable component
import styled, { keyframes } from "styled-components";
import { Chevron } from "../Icons/Chevron";
import type { GridProps } from "./types";

export const DynamicTableContainer = styled.div`
  border: 1px solid #d4dcec;
  border-radius: 8px;
  background-color: #ffffff;


  table {
    max-width: 50vw;
    border-collapse: collapse;
  }

  thead {
    color: #1e1e1e;
    gap: 0px;
    height: 40px;
    border-bottom: 1px solid #d4dcec;
    background-color: #f8f8f8;
  }

  td {
    padding: 4px 16px;
    width: 100%;
  }

  th {
    padding: 8px 16px;
    font-weight: 600;
    font-size: 12px;
    text-align: left;
  }
`;

export const HeaderGrid = styled.div<GridProps>`
  display: grid;
  grid-template-columns: ${(props) =>
    `repeat(${props.nrOfColumns - 1}, 1fr) auto`} ;
  justify-content: space-between;
  padding: 12px 0px;
  border-bottom: 1px solid #d4dcec;
  border-spacing: 2px;
  color: #6e7686;
  font-weight: 500;
  font-size: 12px;
`;

export const RowGrid = styled.div<GridProps>`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr auto;
  grid-template-columns: ${(props) =>
    `repeat(${props.nrOfColumns - 1}, 1fr) auto`} ;

  justify-content: space-between;
  color: #1e1e1e;
  font-size: 14px;

  &:not(:last-child) {
    border-bottom: 1px solid #d4dcec;
  }
  &:hover {
    cursor: pointer;
    background-color: #f8f8f8;
    border-radius: 8px;
   }
`;

export const StyledChevron = styled(Chevron)<{ open: boolean }>`
  transition: transform 0.3s;
  transform: ${(props) => (props.open ? "rotate(180deg)" : "rotate(0deg)")};
  color: #1b4df5;
`;

export const Rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
`;
