import type React from "react";
import { useState, useRef } from "react";
import GenericButton from "../Buttons/GenericButton";

interface UploadFileProps {
  onFileUpload: (files: File[]) => void;
}

const UploadFile: React.FC<UploadFileProps> = ({ onFileUpload }) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFiles = event.target.files;
    if (newFiles) {
      const filesArray = Array.from(newFiles);
      onFileUpload([...filesArray]);
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  return (
    <div>
      <GenericButton onClick={handleButtonClick} type="UploadDocumentButton" />
      <input
        type="file"
        accept=".jpg,.jpeg,.png,.pdf"
        onChange={handleFileChange}
        multiple
        style={{ display: "none" }}
        ref={fileInputRef}
      />
    </div>
  );
};

export default UploadFile;
