import Accordion from "../../../components/Accordion/Accordion";
import { NewTabIcon } from "../../../components/Icons/NewTabIcon";
import { WarningIcon } from "../../../components/Icons/WarningIcon";
import { BorderBottom, CaseInfoContainer, InfoContainer, InfoText, InfoWrapper, LinkContainer, LinkWrapper, StyledLink, WarningContainer } from "./style";
import { useLocation } from 'react-router-dom';
import type { CaseDetailsProps, InfoRowProps, MatchinApplicationsInfoProps } from "./types";

const MatchinApplicationsInfo = ({ flows, applicationInfo }: MatchinApplicationsInfoProps) => {
    const linkText = "Open application in new tab";
    const location = useLocation();
    const path = location.pathname.split('/').slice(0, -3).join('/');

    const onLinkClick = (flowId: string) => {
        window.open(`${path}/${flowId}`, '_blank');
    }

    return (
        <InfoContainer>
            {flows?.map((flow: any, index: number) => {
                const referenceId = flow.referenceId;
                const isFirst = index === 0;
                return (
                    <Accordion title={referenceId} key={`${flow.flowId} + ${index}`} startsExpanded={isFirst}>
                        <CaseDetails flow={flow} applicationInfo={applicationInfo} showMatch />
                        <LinkContainer>
                            <LinkWrapper>
                                <StyledLink onClick={() => onLinkClick(flow.flowId)}>{linkText}</StyledLink>
                                <NewTabIcon />
                            </LinkWrapper>
                        </LinkContainer>
                    </Accordion>
                )
            })}
        </InfoContainer>
    )
}

export const CaseDetails = ({ flow, showLoan, showMatch, applicationInfo }: CaseDetailsProps) => {
    const defaultNoDataText = "No data found";
    const appliedLoanAmount = flow?.data?.offer?.finalLoanAmount || defaultNoDataText;
    const accountNumber = flow?.data?.agentApiApplication?.bankAccountNumber || defaultNoDataText;
    const email = flow?.data?.agentApiApplication?.applicant?.emailAddress || defaultNoDataText;
    const phone = flow?.data?.agentApiApplication?.applicant?.mobileNumber || defaultNoDataText;

    const getApplicationStatus = () => {
        const lastCallback = flow?.data?.lastCallback;
        const businessStatus = flow?.data?.businessStatus;

        let returnStatus = "";
        if (lastCallback) {
            returnStatus = lastCallback.endpoint;
        }
        if (businessStatus) {
            returnStatus = `${returnStatus} (${businessStatus})`;
        }

        return returnStatus

    }

    const checkIfAddressMatch = () => {
        const valuesToCheck = ['streetName', 'streetNumber', 'postalCode', 'city', 'province'];
        const applicationAddress: any = applicationInfo?.addressInformation;
        const flowAddress: { [key: string]: string } | undefined = flow?.data?.agentApiApplication?.applicant?.addressInformation;
        if (!applicationAddress || !flowAddress) {
            return false;
        }
        return valuesToCheck.every((key) => applicationAddress[key] === flowAddress[key]);
    }

    // get the address information for the flow
    const streetName = flow?.data?.agentApiApplication?.applicant?.addressInformation?.streetName;
    const streetNumber = flow?.data?.agentApiApplication?.applicant?.addressInformation?.streetNumber;
    const postalCode = flow?.data?.agentApiApplication?.applicant?.addressInformation?.postalCode;
    const city = flow?.data?.agentApiApplication?.applicant?.addressInformation?.city;
    const province = flow?.data?.agentApiApplication?.applicant?.addressInformation?.province;
    const addressString = `${streetName} ${streetNumber}, ${postalCode} ${city}, ${province}` || defaultNoDataText;

    // Match flags
    const addressMatch = showMatch ? checkIfAddressMatch() : false;
    const accountNumberMatch = showMatch ? accountNumber === applicationInfo?.accountNumber : false;
    const emailMatch = showMatch ? email === applicationInfo?.email : false;
    const phoneNumberMatch = showMatch ? phone === applicationInfo?.phone : false;
    return (
        <CaseInfoContainer>
            {!showLoan && (
                <InfoRow title="Application status" value={getApplicationStatus()} hasMatch={false} hasPadding={!showLoan} />
            )}
            {showLoan && (
                <InfoRow title="Applied loan amount" value={appliedLoanAmount} hasMatch={false} hasPadding={!showLoan} />
            )}
            <InfoRow title="Account number" value={accountNumber} hasMatch={accountNumberMatch} hasPadding={!showLoan} />
            <InfoRow title="Email address" value={email} hasMatch={emailMatch} hasPadding={!showLoan} />
            <InfoRow title="Phone number" value={phone} hasMatch={phoneNumberMatch} hasPadding={!showLoan} />
            <InfoRow title="Address" value={addressString} hasMatch={addressMatch} hasPadding={!showLoan} hasBorderBottom={false} />
            <div style={{
                padding: "0px 10px"
            }}>
            </div>
        </CaseInfoContainer >
    )
}


export const InfoRow = ({ title, value, hasMatch, hasPadding = true, hasBorderBottom = true, isAddress = false }: InfoRowProps) => {
    return (
        <BorderBottom hasPadding={hasPadding} hasBorderBottom={hasBorderBottom} >
            <InfoWrapper>
                <InfoText hasTextRight={false}>{title}</InfoText>
                {hasMatch && <WarningContainer>
                    <WarningIcon />
                    <InfoText hasTextRight={false} style={{
                        color: "#9E3F00"
                    }}>Match</InfoText>
                </WarningContainer>
                }
            </InfoWrapper>
            <InfoText hasTextRight>{value}</InfoText>
        </BorderBottom>
    )
}


export default MatchinApplicationsInfo;