import React from "react";
import { Icons } from "@flow/icons";
import { Collapsible } from "@flow/components";
import { DecisionSectionWrapper } from "../styles";
import { UsertaskButtons, UsertaskDecision, UsertaskInternalComment, UsertaskApplicantInfo } from "../UsertaskComponents";
import CommonUserTask from "../CommonUserTask";
import { Applicant, VideoId } from "@flow";
import { InfoSectionPersonInfoItemWrapper, InfoSectionPersonInfoItem, CustomInfoSectionWrapper, InfoSectionWarningText, InfoSectionPersonsWrapper, InfoSectionPersonInfoWrapper, InfoSectionPersonInfoHeader, RawData } from "./style";

const ManualIdReview = (flow: any) => {
  //Application review comment
  const [comment, setComment] = React.useState(flow.task?.data?.applicationComment ?? "");
  //Application review decision
  const [decision, setDecision] = React.useState(flow.task?.data?.applicationDecision ?? null);

  //If sent from overview we need to handle things a bit differently
  const isFromOverview = flow?.flow?.caseData !== undefined;

  //Data variable
  const data = isFromOverview ? flow?.flow?.caseData?.data : flow.flow?.data;

  //Check if caseworker task is active
  const haveTask = data?.queues ? data?.queues["manual-id-review"] : false;

  const options: { value: string, label: string }[] | null | undefined = [
    { value: "Green", label: "Continue Loan Application" },
    { value: "Red", label: "Reject Loan Application" },
    { value: "Yellow", label: "Retry VideoID process" },
  ];

  delete data.agentApiApplication?.applicant?.psD2Data;
  const applicant = data?.agentApiApplication?.applicant;
  const videoId = data.integrations?.electronicIdVideoId?.videoIdStatus;
  let citizenships = applicant.citizenships;
  if (citizenships.length > 0) {
    citizenships = applicant.citizenships.join();
  }

  //Renders info about the applicant
  const renderPersonInfo = (applicant: Applicant) => {
    return <>
      <InfoSectionPersonInfoItemWrapper style={{ borderTop: "none" }}>
        <InfoSectionPersonInfoItem>Name</InfoSectionPersonInfoItem>
        <InfoSectionPersonInfoItem style={{ display: "flex", alignItems: "center" }}>{applicant.firstName + " " + applicant.lastName}</InfoSectionPersonInfoItem>
      </InfoSectionPersonInfoItemWrapper>
      <InfoSectionPersonInfoItemWrapper style={{ borderTop: "none" }}>
        <InfoSectionPersonInfoItem>National ID</InfoSectionPersonInfoItem>
        <InfoSectionPersonInfoItem>{applicant.nationalId}</InfoSectionPersonInfoItem>
      </InfoSectionPersonInfoItemWrapper>
      <InfoSectionPersonInfoItemWrapper style={{ borderTop: "none" }}>
        <InfoSectionPersonInfoItem>Birth date</InfoSectionPersonInfoItem>
        <InfoSectionPersonInfoItem>{applicant.birthDate.substring(0, 10)}</InfoSectionPersonInfoItem>
      </InfoSectionPersonInfoItemWrapper>
      <InfoSectionPersonInfoItemWrapper style={{ borderTop: "none" }}>
        <InfoSectionPersonInfoItem>Gender</InfoSectionPersonInfoItem>
        <InfoSectionPersonInfoItem>{applicant.gender}</InfoSectionPersonInfoItem>
      </InfoSectionPersonInfoItemWrapper>
      <InfoSectionPersonInfoItemWrapper style={{ borderTop: "none" }}>
        <InfoSectionPersonInfoItem>Citizenships</InfoSectionPersonInfoItem>
        <InfoSectionPersonInfoItem>{citizenships}</InfoSectionPersonInfoItem>
      </InfoSectionPersonInfoItemWrapper>
    </>;
  };

  const renderVideoIdStatus = (videoId: VideoId) => {
    return <>
      <InfoSectionPersonInfoItemWrapper style={{ borderTop: "none" }}>
        <InfoSectionPersonInfoItem>Name</InfoSectionPersonInfoItem>
        <InfoSectionPersonInfoItem style={{ display: "flex", alignItems: "center" }}>{videoId.primaryName + " " + videoId.secondaryName}</InfoSectionPersonInfoItem>
      </InfoSectionPersonInfoItemWrapper>
      <InfoSectionPersonInfoItemWrapper style={{ borderTop: "none" }}>
        <InfoSectionPersonInfoItem>National ID</InfoSectionPersonInfoItem>
        <InfoSectionPersonInfoItem style={{ display: "flex", alignItems: "center" }}>{videoId.personalNumber}</InfoSectionPersonInfoItem>
      </InfoSectionPersonInfoItemWrapper>
      <InfoSectionPersonInfoItemWrapper style={{ borderTop: "none" }}>
        <InfoSectionPersonInfoItem>Birth date</InfoSectionPersonInfoItem>
        <InfoSectionPersonInfoItem style={{ display: "flex", alignItems: "center" }}>{new Date(videoId.birthDate).toISOString().substring(0, 10)}</InfoSectionPersonInfoItem>
      </InfoSectionPersonInfoItemWrapper>
      <InfoSectionPersonInfoItemWrapper style={{ borderTop: "none" }}>
        <InfoSectionPersonInfoItem>Sex</InfoSectionPersonInfoItem>
        <InfoSectionPersonInfoItem style={{ display: "flex", alignItems: "center" }}>{videoId.sex}</InfoSectionPersonInfoItem>
      </InfoSectionPersonInfoItemWrapper>
      <InfoSectionPersonInfoItemWrapper style={{ borderTop: "none" }}>
        <InfoSectionPersonInfoItem>Nationality</InfoSectionPersonInfoItem>
        <InfoSectionPersonInfoItem style={{ display: "flex", alignItems: "center" }}>{videoId.nationality}</InfoSectionPersonInfoItem>
      </InfoSectionPersonInfoItemWrapper>
    </>;
  };

  //Renders the decision section
  const renderDecisionSection = (activeTask: any) => {
    if (activeTask) {
      return <DecisionSectionWrapper>
        {UsertaskApplicantInfo(applicant)}
        {UsertaskDecision(
          decision,
          (e: any) => setDecision(e.target.value),
          options,
        )}
        {UsertaskInternalComment(
          comment,
          (e) => setComment(e.target.value),
        )}
        {UsertaskButtons(
          decision,
          () => flow.complete({
            idCheckDecision: decision,
            pepReviewComment: comment,
          }),
          () => flow.save({ pepComment: comment, pepDecision: decision }),
        )}
      </DecisionSectionWrapper>;
    }

    return null;
  };

  const manualIdReviewContent = () => {
    return (
      <>
        <CustomInfoSectionWrapper>
          <InfoSectionWarningText>
              NationalID from VideoID does not match Applicant. Check if the VideoID is correct.
          </InfoSectionWarningText>
          <InfoSectionPersonsWrapper>
            <InfoSectionPersonInfoWrapper>
              <InfoSectionPersonInfoHeader>
                <div style={{ marginLeft: "20px", display: "flex" }}><Icons.UserAvatar /></div>
                <b style={{ marginLeft: "10px" }}>Applicant</b>
              </InfoSectionPersonInfoHeader>
              {renderPersonInfo(applicant)}
            </InfoSectionPersonInfoWrapper>
            <InfoSectionPersonInfoWrapper>
              <InfoSectionPersonInfoHeader>
                <div style={{ marginLeft: "20px", display: "flex" }}><Icons.UserAvatar /></div>
                <b style={{ marginLeft: "10px" }}>Video ID</b>
              </InfoSectionPersonInfoHeader>
              {renderVideoIdStatus(videoId)}
            </InfoSectionPersonInfoWrapper>
          </InfoSectionPersonsWrapper>
          <Collapsible header="Raw applicant data">
            <RawData >
              <pre> {JSON.stringify(applicant, null, 4)}</pre>
            </RawData>
          </Collapsible>
          <Collapsible header="Raw Video ID data">
            <RawData >
              <pre> {JSON.stringify(videoId, null, 4)}</pre>
            </RawData>
          </Collapsible>
        </CustomInfoSectionWrapper>
        {isFromOverview ? null : renderDecisionSection(haveTask)}
      </>
    );
  };

  return (
    CommonUserTask(manualIdReviewContent())
  );
};

export default ManualIdReview;
