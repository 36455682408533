import {
    ApplicantContainerWCoApp,
    ApplicantIndicatorText,
    ApplicantInfoOverviewContainer,
    ApplicantInfoOverviewWCoAppContainer,
    ApplicantInfoTitleBold,
    ApplicantOverviewContainer,
    ApplicantOverviewInfoContactAnchor,
    ApplicantOverviewInfoContactContainer,
    ApplicantWCoAppOverviewContainer,
    ApplicantContactInfoContainer,
    ApplicantNameContainer,
    ApplicantContactContainer,
    ApplicantName,
    ApplicantNationalIdCopy,
    ApplicantNationalId,
    ApplicantOverviewInfoContactAnchorNew
} from "./style";
import type { Applicant } from "./types";
import { useEffect, useState } from "react";
import { Icons } from "@flow/icons";
import { Copy } from "../Icons/Copy";

const RenderApplicantInfo = (props: {
    applicant: Applicant;
    copiedNationalId: boolean;
    setCopiedNationalId: (value: boolean) => void;
    isCoApplicant?: boolean;
}) => {
    const { applicant, copiedNationalId, setCopiedNationalId, isCoApplicant } = props;
    const { firstName, lastName, nationalId, mobileNumber, emailAddress } = applicant;

    return (
        <ApplicantContainerWCoApp>
            <ApplicantInfoOverviewWCoAppContainer>
                <ApplicantIndicatorText>
                    {isCoApplicant ? "CA" : "MA"}
                </ApplicantIndicatorText>
            </ApplicantInfoOverviewWCoAppContainer>
            <ApplicantContactInfoContainer>
                <ApplicantNameContainer>
                    <ApplicantName>
                    {firstName} {lastName}
                    </ApplicantName>
                    <ApplicantNationalIdCopy onClick={() => setCopiedNationalId(true)}>
                        <ApplicantNationalId>{nationalId}</ApplicantNationalId>
                        {copiedNationalId ? (
                            <Icons.Check />
                        ) :
                        (
                            <Copy />
                        )}
                    </ApplicantNationalIdCopy>
                </ApplicantNameContainer>
                <ApplicantContactContainer>
                    <ApplicantOverviewInfoContactAnchorNew href={`tel:${mobileNumber}`}>
                        {mobileNumber}
                    </ApplicantOverviewInfoContactAnchorNew>
                    <ApplicantOverviewInfoContactAnchorNew href={`mailto:${emailAddress}`}>
                        {emailAddress}
                    </ApplicantOverviewInfoContactAnchorNew>
                </ApplicantContactContainer>
            </ApplicantContactInfoContainer>
        </ApplicantContainerWCoApp>
    );
}

export const ApplicantInfoOverview = (props: {
    applicant: Applicant;
    coapplicant?: Applicant;
}) => {
    const [copiedAppNationalId, setCopiedAppNationalId] = useState(false);
    const [copiedCoAppNationalId, setCopiedCoAppNationalId] = useState(false);
    const { applicant, coapplicant } = props;
    const { firstName, lastName, nationalId, mobileNumber, emailAddress } = applicant;
    const { nationalId: coNationalId } = coapplicant || {};

    useEffect(() => {
        const timeout = setTimeout(() => {
          if (copiedAppNationalId) {
            navigator.clipboard.writeText(nationalId);
            setCopiedAppNationalId(false);
          }
          if (copiedCoAppNationalId) {
            if (coNationalId)
                navigator.clipboard.writeText(coNationalId);
            setCopiedCoAppNationalId(false);
          }
        }, 1000);
    
        return () => clearTimeout(timeout);
    }, [copiedAppNationalId, copiedCoAppNationalId]);

    if (coapplicant) {
        return (
            <ApplicantWCoAppOverviewContainer>
                {RenderApplicantInfo({ applicant, copiedNationalId: copiedAppNationalId, setCopiedNationalId: setCopiedAppNationalId })}
                {RenderApplicantInfo({ applicant: coapplicant, copiedNationalId: copiedCoAppNationalId, setCopiedNationalId: setCopiedCoAppNationalId, isCoApplicant: true })}
            </ApplicantWCoAppOverviewContainer>
        );
    }

    return (
        <ApplicantOverviewContainer>
            <ApplicantInfoOverviewContainer>
                <ApplicantInfoTitleBold>
                    {firstName} {lastName}
                </ApplicantInfoTitleBold>
                {nationalId}
            </ApplicantInfoOverviewContainer>
            <ApplicantOverviewInfoContactContainer>
                <ApplicantOverviewInfoContactAnchor href={`tel:${mobileNumber}`}>
                    {mobileNumber}
                </ApplicantOverviewInfoContactAnchor>
                <ApplicantOverviewInfoContactAnchor href={`mailto:${emailAddress}`}>
                    {emailAddress}
                </ApplicantOverviewInfoContactAnchor>
            </ApplicantOverviewInfoContactContainer>
        </ApplicantOverviewContainer>
    );
};
