/* eslint-disable */
import React from "react";
import Branding from "../branding/Branding";
import LoginLogo from "../branding/LoginLogo";
import MainLogo from "../branding/MainLogo";
import translatesInbox from "../translate/inbox.json";
import { CaseManager, APPS, FEATURES } from "@flow/case-manager";
import * as case_and_queue_config from "./case-and-queue-config";

import { format } from "date-fns";

// import { useFlags } from "launchdarkly-react-client-sdk";

export const Portal = () => {
  // Use the `useFlags` hook to access feature flags in your components
  // const { firstFeature123 } = useFlags();
  const leaBankTheme = {
    branding: Branding,
    customerIcon: LoginLogo,
    mainLogo: MainLogo,
  };

  const customTranslations = [
    {
      app: APPS.Inbox,
      language: "en",
      translates: translatesInbox,
    },
  ];

  const pollingInterval = 60000;

  // Date 14 days ago, used for rejected queue
  const today = new Date();
  const date14DaysAgo = new Date(
    today.setDate(today.getDate() - 14),
  ).toISOString();

  return (
    <CaseManager
      configuration={() => {
        return {
          homeUrl: "/inbox",
          language: "en",
          skin: leaBankTheme,
          apps: [APPS.Inbox, APPS.Search, APPS.Process],
          inboxConfiguration: {
            features: [FEATURES.CaseNotes],
            searchFields: ["data.agentApiApplication.agentId"],
            queuesPollingEnabled: true,
            startableProcesses: ["loan-es", "manual-cleanup", "common-process"],
          },
          searchConfiguration: {
            features: [FEATURES.CaseNotes],
            searchProcesses: ["loan-es", "loan-es-credit-decision"],
            searchFields: [
              // Displayed for all processes
              { name: "Flow Application ID", value: "flowId" },
              { name: "Agent Used", value: "data.agentApiApplication.agentId" },
              {
                name: "External Application ID",
                value: "data.agentApiApplication.externalApplicationId",
              },
              {
                name: "Status",
                value: "data.lastCallback.endpoint",
                type: "select",
                options: [
                  { value: "approved", label: "Approved" },
                  { value: "canceled", label: "Canceled" },
                  { value: "declined", label: "Declined" },
                  { value: "disbursed", label: "Disbursed" },
                  { value: "document-signed", label: "Document Signed" },
                  {
                    value: "ready-for-disbursement",
                    label: "Ready For Disbursement",
                  },
                ],
              },
              {
                name: "Applicant's First Name",
                value: "data.agentApiApplication.applicant.firstName",
                queryOperator: "contains",
              },
              {
                name: "Applicant's Last Name",
                value: "data.agentApiApplication.applicant.lastName",
                queryOperator: "contains",
              },
              {
                name: "Applicant's National ID",
                value: "data.agentApiApplication.applicant.nationalId",
              },
              {
                name: "Applicant's Phone Number",
                value: "data.agentApiApplication.applicant.mobileNumber",
              },
              {
                name: "Applicant's E-mail",
                value: "data.agentApiApplication.applicant.emailAddress",
              },
              {
                name: "Campaign Id",
                value: "data.agentApiApplication.campaignId",
              },
              {
                name: "Created after",
                value: "createdAt",
                type: "date",
                queryOperator: "gte",
                max: format(Date.now(), "yyyy-MM-dd"),
              },
              {
                name: "Created before",
                value: "createdAt",
                type: "date",
                queryOperator: "lt",
                max: format(Date.now(), "yyyy-MM-dd"),
              },
            ],
            searchResultFields: [
              { name: "Flow Application ID", value: "flowId" },
              { name: "Agent Used", value: "data.agentApiApplication.agentId" },
              {
                name: "Applicant's First Name",
                value: "data.agentApiApplication.applicant.firstName",
              },
              {
                name: "Applicant's Last Name",
                value: "data.agentApiApplication.applicant.lastName",
              },
              {
                name: "Applicant's Phone Number",
                value: "data.agentApiApplication.applicant.mobileNumber",
              },
              {
                name: "Applicant's E-mail",
                value: "data.agentApiApplication.applicant.emailAddress",
              },
              {
                name: "Campaign Id",
                value: "data.agentApiApplication.campaignId",
                mapping: (value: any) => value.toString(),
              },
              { name: "Status", value: "data.lastCallback.endpoint" },
              {
                name: "Created",
                value: "createdAt",
                type: "date",
              },
            ],
            search: {
              sort: [
                {
                  name: "Recently changed",
                  value: "updatedAt",
                  direction: "desc",
                },
                { name: "Oldest", value: "createdAt", direction: "asc" },
                { name: "Newest", value: "createdAt", direction: "desc" },
              ],
            },
            searchQueryView: "caseworker-search-result", // defined in setup/process/common/views/caseworker-search-result.js
          },
          caseNotesConfiguration: {
            sort: "DESC",
          },
          customComponents: case_and_queue_config.CustomComponents(),
          translates: customTranslations,
        };
      }}
    />
  );
};
