import styled from "styled-components";

interface ApplicantInfoBlockProps {
  isLast?: boolean;
}

export const ApplicantCollectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const ApplicantContainer = styled.div`
  display: flex;
  border: 1px solid #d4dcec;
  border-radius: 8px;
  font-size: 12px;
  line-height: 125%;
  overflow: hidden;
  margin: 0 28px;
  
`;

export const ApplicantOverviewContainer = styled.div`
    display: flex;
    border: 1px solid #B8C4E0;
    border-radius: 8px;
    font-size: 14px;
    align-items: center;
    justify-content: space-between;
`;

export const ApplicantRole = styled.div`
    display: flex;
    align-items: center;
    background-color: #F8F9FC;
    color: #2969FF;
    padding: 16px 6px;
    font-weight: 600;
`;

export const ApplicantInfoContainer = styled.div`
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 6px 6px 6px 6px;
`;

export const ApplicantInfoOverviewContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 8px;
    gap: 4px;
    font-weight: 300;
`;

export const ApplicantInfoBlock = styled.div<ApplicantInfoBlockProps>`
  display: flex;
  gap: 6px;
  flex-direction: column;
  align-items: ${(props: any) => (props.isLast ? "flex-end" : "none")};
  text-align: ${(props: any) => props.isLast && "end"};
`;

export const ApplicantInfoTitleBold = styled.b`
    font-weight: 600;
    font-size: 16px;
`;

export const ContactLinks = styled.a`
  color: #2f80ed;
  text-decoration: none;
  word-break: break-word;
`;

export const ApplicantOverviewInfoContactContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 8px;
    gap: 4px;
`;

export const ApplicantOverviewInfoContactAnchor = styled.a`
    color: #2F80ED;
    font-weight: 400;
    text-decoration: none;
`;

export const ApplicantContactInfoContainer = styled.div`
    display: flex;
    padding: 8px;
    justify-content: space-between;
    align-items: center;
    flex: 1 0 0;
`;

export const ApplicantNameContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
`;

export const ApplicantContactContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 8px;
`;

export const ApplicantWCoAppOverviewContainer = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
`;

export const ApplicantContainerWCoApp = styled.div`
    display: flex;
    align-items: center;
    flex: 1 0 0;
    border-radius: 8px;
    border: 1px solid #B8C4E0;
    background: #FFF;
`;

export const ApplicantInfoOverviewWCoAppContainer = styled.div`
    display: flex;
    width: 44px;
    padding: 8px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 4px 0 0 4px;
    border-right: 1px solid #B8C4E0;
    background: #F1F3F9;
`;

export const ApplicantIndicatorText = styled.div`
    color: #1B4DF5;
    text-align: center;
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: '140%';
`;

export const ApplicantName = styled.span`
    color: #1E1E1E;
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: '140%';
`;

export const ApplicantNationalId = styled.span`
    color: #6E7686;
    font-family: 'Inter';
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: '140%';
`;

export const ApplicantNationalIdCopy = styled.div`
    display: flex;
    gap: 4px;
    align-items: center;
    cursor: pointer;
`;

export const ApplicantOverviewInfoContactAnchorNew = styled.a`
    color: #1B4DF5;
    text-align: right;
    font-family: 'Inter';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: '140%';
    text-decoration: none;
`;