import axios from "axios";

export const getMessageTasks = async (flowId: string) => {
  try {
    const { data } = await axios.get(
      `/api/flow/tasks?flowId=${flowId}&status=pending&taskCategory=message-task`,
    );
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw Error(
        `Trigger message task in Flow failed: ${JSON.stringify(error?.response?.data)}`,
      );
    }
    throw Error(`Trigger message task in Flow failed: ${error}`);
  }
};

export const triggerMessageTask = async (taskId: string, requestBody: any) => {
  try {
    const { data } = await axios.post(
      `/api/flow/tasks/${taskId}/trigger`,
      requestBody,
    );
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw Error(
        `Trigger message task in Flow failed: ${JSON.stringify(error?.response?.data)}`,
      );
    }
    throw Error(`Trigger message task in Flow failed: ${error}`);
  }
};
