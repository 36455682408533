import styled from "styled-components";

type FormComponentWrapperProps = {
  withoutGaps?: boolean;
};

export const FormComponentContainer = styled.div<FormComponentWrapperProps>`
  display: flex;
  flex-direction: column;
  padding: 0 28px;
  gap: ${(props: any) => !props.withoutGaps && "10px"};
  color: #2b2b2b;
`;
