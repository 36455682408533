import {
  ApplicantContainer,
  ApplicantInfoBlock,
  ApplicantInfoContainer,
  ApplicantRole,
  ContactLinks,
} from "./style";
import type { Applicant } from "./types";

type ApplicantProps = Applicant & {
  isCoApplicant?: boolean;
};

export const ApplicantInfo = (props: {
  applicant: ApplicantProps;
}) => {
  return (
    <ApplicantContainer>
      <ApplicantRole>
        {props.applicant.isCoApplicant ? "CA" : "MA"}
      </ApplicantRole>
      <ApplicantInfoContainer>
        <ApplicantInfoBlock>
          <div style={{ fontWeight: "600", color: "#333" }}>
            {`${props.applicant.firstName} ${props.applicant.lastName}`}
          </div>
          <div style={{ color: "#5E676A" }}>{props.applicant.nationalId}</div>
        </ApplicantInfoBlock>
        <ApplicantInfoBlock isLast>
          <ContactLinks href={`tel:${props.applicant.mobileNumber}`}>
            {props.applicant.mobileNumber}
          </ContactLinks>
          <ContactLinks href={`mailto:${props.applicant.emailAddress}`}>
            {props.applicant.emailAddress}
          </ContactLinks>
        </ApplicantInfoBlock>
      </ApplicantInfoContainer>
    </ApplicantContainer>
  );
};
