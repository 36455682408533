import { NormalRows } from "../../views/overview/types";
import { OverviewRow } from "./OverviewRow";

export const OverviewRowCollection = (props: {
    rows: NormalRows;
}) => {
    const { rows } = props;
    return rows.map((row, index) => {
        const isLast =
            index === rows.length - 1 ||
            (rows.length % 2 === 0 && index === rows.length - 2);
        return (<OverviewRow label={row.name} value={row.value} isLast={isLast} key={index} />)
    });
};
