import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { CM1RawData, FlexColumn, InfoSectionWrapper, NormalInfoWrapper, SVGArrowWrapper } from "../styles";
import { CenteredSpinner, Icons } from "@flow/icons";
import { getElectronicIdData, getElectronicIdVideo, getTransactionsByFlowId } from "../../../services/electronicId.service";
import CommonUserTask from "../CommonUserTask";
import { Collapsible } from "@flow/components";
import { MediaWrapper, ImageWrapper, VideoWrapper, InfoWrapper, DataWrapper } from "./styles";

type Transaction = {
  id: number;
};

const VideoIdView = (electronicId: any) => {
  const [isCustomerOpen, setIsCustomerOpen] = useState(false);
  const [isIdOpen, setIsIdOpen] = useState(false);
  const [isVideoOpen, setIsVideoOpen] = useState(false);
  const [isVideoIdDataOpen, setIsVideoIdData] = useState(false);
  const [isSignatureIdDataOpen, setIsSignatureIdData] = useState(false);
  const [isTransactionsOpen, setIsTransactionsOpen] = useState(false);

  const [data, setData] = useState<any | null>(null);
  const [mp4, setMp4] = useState<string | null>("");
  const [transactions, setTransactions] = useState<any>([]);

  const videoId = electronicId?.videoId;
  const videoVerificationResponse = videoId?.requestVideoVerificationResponse;
  const signatureIdResponse = electronicId?.signatureId?.identity?.registerIdentityResponse;

  const videoidDataConfig = [
    {
      title: "Id",
      value: videoVerificationResponse?.id,
    },
    {
      title: "Tenant Id",
      value: videoVerificationResponse?.tenantId,
    },
    {
      title: "Rauthority Id",
      value: videoVerificationResponse?.rauthorityId,
    },
    {
      title: "Full Name",
      value: videoVerificationResponse?.fullName,
    },
    {
      title: "Priority",
      value: videoVerificationResponse?.priority,
    },
    {
      title: "Status",
      value: videoVerificationResponse?.status,
    },
  ];

  const signatureIdDataConfig = [
    {
      title: "Id",
      value: signatureIdResponse?.id,
    },
    {
      title: "Identification Id",
      value: signatureIdResponse?.identificationId,
    },
    {
      title: "NIF",
      value: signatureIdResponse?.nif,
    },
    {
      title: "Primary Name",
      value: signatureIdResponse?.primaryName,
    },
    {
      title: "Secondary Name",
      value: signatureIdResponse?.secondaryName,
    },
    {
      title: "Personal Info Id",
      value: signatureIdResponse?.personalInfoId,
    },
    {
      title: "External Reference",
      value: signatureIdResponse?.externalReference,
    },
    {
      title: "Status",
      value: signatureIdResponse?.status,
    },
    {
      title: "Is New Identity",
      value: signatureIdResponse?.isNewIdentity ? "Yes" : "No",
    },
  ];

  const videoIdConfig = [
    {
      title: "Customer",
      toggleFunction: () => setIsCustomerOpen(!isCustomerOpen),
      isOpen: isCustomerOpen,
      content: <MediaWrapper><ImageWrapper
        src={`data:image/png;base64,${data?.biometrics?.face?.image}`}
      /></MediaWrapper>,
    },
    {
      title: "ID",
      toggleFunction: () => setIsIdOpen(!isIdOpen),
      isOpen: isIdOpen,
      content: <MediaWrapper>
        {data?.document?.front && data.document?.back ? <>
          <ImageWrapper
            src={`data:image/png;base64,${data?.document?.front}`}
          />
          <ImageWrapper
            src={`data:image/png;base64,${data?.document?.back}`}/></>
          : <ImageWrapper
            src={`data:image/png;base64,${data?.document?.scan}`}
          />
        }</MediaWrapper>,
    },
    {
      title: "Video",
      toggleFunction: () => setIsVideoOpen(!isVideoOpen),
      isOpen: isVideoOpen,
      content:
      <MediaWrapper>
        <VideoWrapper controls>
          <source src={mp4 ?? ""} type="video/mp4" />
        </VideoWrapper>
      </MediaWrapper>
      ,
    },
    {
      title: "Video ID Data",
      toggleFunction: () => setIsVideoIdData(!isVideoIdDataOpen),
      isOpen: isVideoIdDataOpen,
      content:
        <div style={{ width: "100%" }}>
          {videoidDataConfig.map((item, i) => (
            <NormalInfoWrapper key={i}>
              <b>{item.title}</b> {item.value}
            </NormalInfoWrapper>
          ))}
          {videoVerificationResponse?.data && Object.keys(videoVerificationResponse?.data).length > 1 ? <NormalInfoWrapper><div><b>Data</b></div><div>{Object.keys(videoVerificationResponse?.data).map((keyName, i) => (
            <div key={i}>
              <b>{keyName}</b> {videoVerificationResponse?.data[keyName]}
            </div>
          ))}</div></NormalInfoWrapper> : null}
        </div>,
    },
    {
      title: "Signature ID Data",
      toggleFunction: () => setIsSignatureIdData(!isSignatureIdDataOpen),
      isOpen: isSignatureIdDataOpen,
      content:
        <div style={{ width: "100%" }}>
          {signatureIdDataConfig.map((item, i) => (
            <NormalInfoWrapper key={i}>
              <b>{item.title}</b> {item.value}
            </NormalInfoWrapper>
          ))}
        </div>,
    },
    {
      title: "Latest Transactions",
      toggleFunction: () => getTransactionsAndToggleContent(),
      isOpen: isTransactionsOpen,
      content:
        <div style={{ display: "flex", flexDirection: "column", width: "100%", backgroundColor: "#f5f5f5" }}>
          <NormalInfoWrapper style={{ width: "100%" }}>
            {transactions?.length === 0 ? <CenteredSpinner /> :
              <div>
                {transactions?.items?.map((item: any, i: number) => (
                  <Collapsible key={"Collapsible-" + i}  header={"Verification security code " + item.id}>
                    <CM1RawData>
                      <pre> {JSON.stringify(item, null, 4)}</pre>
                    </CM1RawData>
                  </Collapsible>
                ))}
              </div>
            }
          </NormalInfoWrapper>
          {transactions?.totalPages > transactions?.page + 1 ?
            <a onClick={() => loadMoreTransactions()} style={{ display: "flex", justifyContent: "center", alignItems: "center", paddingTop: "10px", color: "#2F80ED", cursor: "pointer" }}>Load more...</a>
            : null
          }
        </div>,
    },
  ];

  useEffect(() => {
    const getDataFromElectronicId = async () => {
      if (data === null) {
        const response = await getElectronicIdData(videoId?.videoIdStatus?.id);
        setData(response.data);
      }};

    const getVideoFromElectronicId = async () => {
      if (mp4 === "") {
        const mp4Data = await getElectronicIdVideo(videoId?.videoIdStatus?.id);
        setMp4(mp4Data);
      }};

    getDataFromElectronicId();
    getVideoFromElectronicId();

    return () => {
      setData(null);
      URL.revokeObjectURL(mp4 ?? "");
    };
  }, []);

  const getTransactionsAndToggleContent = async () => {
    setIsTransactionsOpen(!isTransactionsOpen);

    if (transactions.length === 0) {
      const response = await getTransactionsByFlowId(electronicId?.flowId, 0);
      setTransactions(response.data);
    }
  };

  const loadMoreTransactions = async () => {
    const response = await getTransactionsByFlowId(electronicId?.flowId, transactions?.page + 1);
    setTransactions({ ...response.data, items: [...transactions?.items, ...response.data.items] });
  };

  const renderVideoIdConfig = (config: any[]) => {
    return config.map((item, index) => {
      return (<>
        <NormalInfoWrapper key={index} onClick={item.toggleFunction} style={{ cursor: "pointer" }}>
          <div>{item.title}</div>
          <SVGArrowWrapper>{!item.isOpen ? <Icons.ChevronDown /> : <Icons.ChevronUp />}</SVGArrowWrapper>
          {item.toggleContent}
        </NormalInfoWrapper>
        {item.isOpen ?
          <NormalInfoWrapper key={index + "-unique"} style={{ backgroundColor: "rgba(240, 250, 241, 0.5)", justifyContent: "center", padding: 0 }}>
            {item.content}
          </NormalInfoWrapper> : null}
      </>
      );
    });
  };

  const videoIdViewContent = () => {
    return (
      <InfoWrapper>
        <DataWrapper>
          {renderVideoIdConfig(videoIdConfig)}
        </DataWrapper>
      </InfoWrapper>
    );
  };

  return (
    CommonUserTask(videoIdViewContent())
  );
};

export default VideoIdView;
