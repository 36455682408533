import AddCircle from "../Icons/AddCircle";
import { StyledGenericButton } from "./styles";
import type { GenericButtonProps } from "./types";

const ButtonTypes = {
  AddNewButton: {
    icon: <AddCircle />,
    label: "Add New",
    color: "#1b4df5",
    border: "2px solid #1b4df5",
    ColorOnHover: "#4c72ef",
    BorderColorOnHover: "#4c72ef",
  },
  UploadDocumentButton: {
    icon: <AddCircle />,
    label: "Upload Document",
    color: "#1b4df5",
    border: "2px solid #1b4df5",
    ColorOnHover: "#4c72ef",
    BorderColorOnHover: "#4c72ef",
  },
};

const GenericButton = (props: GenericButtonProps): JSX.Element => {
  const { icon, label, color, border, ColorOnHover, BorderColorOnHover } =
    ButtonTypes[props.type];
  return (
    <StyledGenericButton
      onClick={props.onClick}
      color={color}
      border={border}
      BorderColorOnHover={BorderColorOnHover}
      ColorOnHover={ColorOnHover}
    >
      {icon}
      <div>{label}</div>
    </StyledGenericButton>
  );
};

export default GenericButton;
