// import styled from "styled-components";
import styled from "styled-components";

// This function makes sure that the caseworker portal is pink in dev environments, and light blue otherwise.
const getColorForEnvironment = () => {
  const getEnvironment = () => {
    if (location.hostname === "localhost") return "dev";
    return window.location.hostname.split(".")[1];
  };

  const environment = getEnvironment();
  switch (true) {
  case environment.includes("dev"):
    return "#027ef2";
  case environment.includes("test"):
    return "#71427b";
  default:
    return "#0ac2a7";
  }
};

export default styled.div`
  @keyframes animation {
    0%{
      background-color: #71427b;
    }
    20%{
      background-color: #027ef2;
    }
    40%  {
      background-color: #0ac2a7;
    }
    60%  {
    background-color: #fe653e;
  }
    80%  {
    background-color: #0D0D0D;
  }
    100%  {
    background-color: #71427b;
  }
  }

  .mainHeader {
    //animation: 1s animation ease-in-out infinite;
    background-color: "fff"
  }

  .mainMenu {
    //animation: 1s animation ease-in-out infinite;
    background-color: ${getColorForEnvironment()}
  }

  .mainMenuIcon.active {
    color: white;
    border-left: 3px solid white;
  }

  .activeElement {
    border-left: 2px solid blue;
  }
`;
