import React from "react";
import axios from "axios";
import mime from "mime-types";
import { FaFilePdf, FaFileCode } from "react-icons/fa6";
import { StyledDownloadButton } from "./styles";

type FileData = {
  filename: string;
};

export const renderDownloadButton = (
  flowId: string,
  attachmentKey: string,
  fileData: FileData
) => {
  // Helper function to get the file extension of an attachment.
  const getAttachmentFileExtension = async (
    flowId: string,
    attachmentKey: string
  ) => {
    const url = `/api/flow/flows/${flowId}/attachments/${attachmentKey}`;
    const response = await axios.get(url);
    const fileExtension = mime.extension(response.data.attachment.mimetype);
    return fileExtension;
  };

  // Helper function to download the attachment.
  const downloadAttachment = async (flowId: string, attachmentKey: string) => {
    const fileExtension = await getAttachmentFileExtension(
      flowId,
      attachmentKey
    );

    try {
      const url = `/api/flow/flows/${flowId}/attachments/${attachmentKey}/download`;
      const response = await axios.get(url, {
        responseType: "arraybuffer",
      });

      let blob;
      // All PDFs we get from the Leabanks DGS (document generation service) are actually base64 encoded text files.
      // therefore, we need to make sure that the caseworker downloads an encoded + converted file, and not a decoded one.
      if (fileExtension === "pdf") {
        const base64EncodedFile = response.data;

        // Convert the base64 encoded file to a binary format
        const binaryFile = Buffer.from(base64EncodedFile, "base64");

        // Decode the binary file as a UTF-8 string
        const base64EncodedString = binaryFile.toString("utf-8");
        const decodedString = atob(base64EncodedString);

        // Convert the decoded string to a Uint8Array
        const uint8Array = new Uint8Array(decodedString.length);
        for (let i = 0; i < decodedString.length; ++i) {
          uint8Array[i] = decodedString.charCodeAt(i);
        }

        // Convert back to downloadable PDF.
        blob = new Blob([uint8Array], { type: "application/pdf" });
      } else {
        // Otherwise, just download it as is.
        blob = new Blob([response.data], { type: "application/octet-stream" });
      }

      // Logic for actually downloading the file in the browser.
      const temporaryUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = temporaryUrl;
      link.setAttribute("download", `${attachmentKey}.${fileExtension}`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  return (
    <StyledDownloadButton
      onClick={() => downloadAttachment(flowId, attachmentKey)}
    >
      Download{" "}
      {fileData?.filename.slice(-3) === "pdf" ? <FaFilePdf /> : <FaFileCode />}
    </StyledDownloadButton>
  );
};
