/* eslint-disable react/prop-types */
import React from "react";
import {
  DownloadFileWrapper,
  FileHeader,
  FileName,
  FileGroup,
  StyledCollapsible,
  File,
  Container,
  TimeStamp,
  DownloadButtonArea,
} from "./styles";
import { DownloadAttachmentButton } from "@flow/components";
import { renderDownloadButton } from "../../components/Buttons/DownloadButton";
import type { Attachment, Flow } from "@flow";

const timeFormat: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "numeric",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
  hour12: false,
};

export const AttachmentsTab = ({ flow }: { flow: Flow }) => {
  const attachments = flow?.data?.attachments;
  const refinanceDocuments = flow?.data?.refinanceDocumentation?.documents;
  const caseworkerUploadedRefinanceDocuments =
    flow?.data?.debtConsolidation?.files;
  const camelCaseToProperCaseConversion = (fileName: string) => {
    const words = fileName.split(/(?=[A-Z])/);
    const properCaseWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1),
    );
    return properCaseWords.join(" ");
  };

  const AttachmentsComponents = // Merge attachments and uniqueDownPaymentPlans
    Object.entries({ ...attachments })
      // Sort the attachments by the date they were uploaded: newest first
      .sort(
        ([, a], [, b]) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
      )
      .map(([fileName, fileData]) => {
        return (
          <FileGroup key={fileName}>
            <FileHeader>
              <StyledCollapsible
                isOpen={true}
                header={camelCaseToProperCaseConversion(fileName)}
              >
                <br />
                <File>
                  <p>Filename</p>
                  <p>Uploaded</p>
                </File>
                <File key={fileData.title}>
                  <FileName>{fileData?.filename}</FileName>
                  <TimeStamp>
                    {new Intl.DateTimeFormat("default", timeFormat).format(
                      new Date(fileData.createdAt),
                    )}
                  </TimeStamp>
                </File>
                <DownloadButtonArea>
                  <DownloadFileWrapper>
                    {renderDownloadButton(flow.flowId, fileName, fileData)}
                  </DownloadFileWrapper>
                </DownloadButtonArea>
              </StyledCollapsible>
            </FileHeader>
          </FileGroup>
        );
      });

  // Add refinance documents and caseworker uploaded refinance documents to the AttachmentsComponents
  if (refinanceDocuments) {
    for (const document of refinanceDocuments) {
      AttachmentsComponents.push(
        <FileGroup key={document.filename}>
          <FileHeader>
            <StyledCollapsible
              isOpen={true}
              header={camelCaseToProperCaseConversion(document?.filename ?? "")}
            >
              <br />
              <File key={document.filename}>
                <FileName>{document.filename}</FileName>
              </File>
              <DownloadButtonArea>
                <DownloadFileWrapper>
                  <DownloadAttachmentButton
                    flowId={flow.flowId}
                    attachmentKey={document?.filename
                      ?.replace(/ /g, "-")
                      .replace(/\./g, "-")}
                  />
                </DownloadFileWrapper>
              </DownloadButtonArea>
            </StyledCollapsible>
          </FileHeader>
        </FileGroup>,
      );
    }
  }
  if (caseworkerUploadedRefinanceDocuments) {
    for (const document of caseworkerUploadedRefinanceDocuments) {
      AttachmentsComponents.push(
        <FileGroup key={document.filename}>
          <FileHeader>
            <StyledCollapsible
              isOpen={true}
              header={camelCaseToProperCaseConversion(document?.filename ?? "")}
            >
              <br />
              <File key={document.filename}>
                <FileName>{document.filename}</FileName>
              </File>
              <DownloadButtonArea>
                <DownloadFileWrapper>
                  <DownloadAttachmentButton
                    flowId={flow.flowId}
                    attachmentKey={document?.attachmentKey}
                  />
                </DownloadFileWrapper>
              </DownloadButtonArea>
            </StyledCollapsible>
          </FileHeader>
        </FileGroup>,
      );
    }
  }

  return <Container>{AttachmentsComponents}</Container>;
};

export const ErrorsAttachmentsTab = ({ flow }: { flow: Flow }) => {
  const attachments = flow.data.attachments;
  // const filteredAttachments = Object.filter(
  // 	attachments,
  // 	([fileName, fileData]) => fileData.filename.slice(0, 6) === "Errors",
  // );
  const filteredAttachments = Object.fromEntries(
    Object.entries(attachments).filter(
      ([fileName, fileData]) => fileData.filename.slice(0, 6) === "Errors",
    ),
  );
  return (
    <Container>
      {Object.entries(filteredAttachments).map(([fileName, fileData]) => {
        return (
          <FileGroup key={fileName}>
            <FileHeader>
              <StyledCollapsible header={fileName}>
                <File>
                  <p>{"File Name"}</p>
                  <p>{"Uploaded"}</p>
                </File>
                <File key={fileData.title}>
                  <FileName>{fileData?.filename}</FileName>
                  <TimeStamp>
                    {new Intl.DateTimeFormat("default", timeFormat).format(
                      new Date(fileData.createdAt),
                    )}
                  </TimeStamp>
                  <DownloadFileWrapper>
                    <DownloadAttachmentButton
                      flowId={flow.flowId}
                      attachmentKey={fileName}
                    />
                  </DownloadFileWrapper>
                </File>
              </StyledCollapsible>
            </FileHeader>
          </FileGroup>
        );
      })}
    </Container>
  );
};
