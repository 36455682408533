/**
 * Used to convert a string in camel case to a readable string suited for display.
 * @param input a string in camel case
 * @returns The input string as a string with spaces between words and capitalized first letter
 */
export const camelCaseToWords = (input: string): string => {
  // Split the string into words based on capital letters
  let result = input.replace(/([A-Z])/g, " $1");

  // Convert the first character of the result to uppercase
  result = result.charAt(0).toUpperCase() + result.slice(1).toLowerCase();

  // Return the resulting string
  return result;
};
