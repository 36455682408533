import styled from "styled-components";
import { FlexColumn, getColorForEnvironment } from "../styles";

export const DecisionSectionDivInfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #DAD9D8;
  height: 30px;
`;

export const InfoSectionAccountWrapper = styled(FlexColumn)`
  width: 370px;
  background-color: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0px 15px 40px rgba(184, 184, 184, 0.2), 0px 5px 10px rgba(184, 184, 184, 0.05);
  overflow: auto;
  max-height: 100%;

  /* hide scrollbar */
  &::-webkit-scrollbar {
    width: 0.2em;
    background-color: #F5F5F5;
  }

  &::-webkit-scrollbar-thumb {
  background-color: #F5F5F5;
  }

  &::-webkit-scrollbar-thumb:hover {
  background-color: #F5F5F5;
  }
`;

export const InfoSectionLessThanThreeWrapper = styled.div`
  display: flex;
  justify-content: center;
  grid-gap: 20px;
  padding: 25px 60px;
  height: 100%;
  box-sizing: border-box;
  align-items: flex-start;

  @media only screen and (max-width: 850px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const InfoSectionMoreThanTwoWrapper = styled(FlexColumn)`
  align-items: center;
  grid-gap: 20px;
  padding: 25px 60px;
  height: 100%;
  box-sizing: border-box;
`;

export const InfoSectionAccountInfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50px;
  background-color: ${getColorForEnvironment()};
  flex: 0 0 50px;
`;

export const InfoSectionAccountInfoSection = styled(FlexColumn)`
  padding: 0 20px;
`;

export const InfoSectionAccountInfoBold = styled.b`
  font-weight: 700;
  font-size: 12px;
  color: #474300;
`;

export const InfoSectionTransactionSelectWrapper = styled(FlexColumn)`
  width: 370px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  grid-gap: 5px;
`;

export const InfoSectionTransactionSelect = styled.select`
  height: 40px;
  text-indent: 10px;
  border: 1px solid #B8B3AF;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
`;

export const InfoSectionTransactions = styled.div`
  display: flex;
  grid-gap: 20px;
  height: calc(100% - 85px);
  box-sizing: border-box;
  align-items: flex-start;

  @media only screen and (max-width: 900px) {
    flex-direction: column;
  }
`;

export const InfoSectionTransactionInfoWrapper = styled(FlexColumn)`
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  /* height: 70px; */
  flex: 0 0 70px;
  border-bottom: 1px solid #E2E2E1;
  padding: 10px 0;
  flex-basis: auto;

  &:last-child {
    border-bottom: none;
  }
`;

export const InfoSectionTransactionInfoDate = styled.div`
  margin: 0;
  padding: 0 20px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.52);
  /* height: 100%; */
  display: flex;
  align-items: flex-end;
`;

export const InfoSectionTransactionDetails = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  /* height: 100%; */
`;

export const InfoSectionTransactionDetailsPadding = styled.div`
  margin: 0;
  padding: 0 20px;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  overflow-wrap: anywhere;
  max-width: 60%;
`;

export const InfoSectionMoreThanTwoSelectSection = styled.div`
  display: flex;
  grid-gap: 20px;

  @media only screen and (max-width: 900px) {
    flex-direction: column;
  }
`;