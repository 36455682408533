import axios from "axios";

export async function uploadFile(files: any[]) {
  const form = new FormData();

  for (let i = 0; i < files.length; i++) {
    form.append("files", files[i]);
  }
  const { data } = await axios.post("/api/flow/files", form);
  return {
    uploadedFilesResponse: data?.uploadedFiles,
  };
}

export async function getFiles(fileId: string) {
  return axios
    .get(`/api/flow/files/${fileId}`)
    .then((response) => response.data);
}

export async function deleteFile(fileId: string) {
  return await axios
    .delete(`/api/flow/files/${fileId}`)
    .then((response) => response.data);
}
