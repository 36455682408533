import styled from "styled-components";

export const InfoTitle = styled.div`
  padding-left: 28px;
  font-weight: 600;
  display: flex;
  color: #2b2b2b;
  font-size: 12px;
`;

export const InfoHeader = styled.div`
  color: #2b2b2b;
  border-radius: 8px 8px 0 0;
  display: flex;
  justify-content: space-between;
  padding: 0 28px;
  font-weight: 700;
  &:hover {
    font-size: 14.1px;
    color: #000;
  }
`;

export const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 7px 28px;
  align-items: center;
  border-bottom: 1px #dad9d8 solid;
  gap: 10px;
`;

export const InfoValue = styled.div`
  text-align: end;
`;

export const CollapseableInfoContainer = styled.div`
  background-color: #fff;
  width: 375px;
  border-radius: 8px;
`;

export const CollapseableInfoHeader = styled.div`
  display: flex;
  padding: 12px 18px;
  justify-content: space-between;
  background-color: #fdfdfd;
  border: 1px solid #dad9d8;
  border-radius: 8px 8px 0 0;
  color: #474847;
  font-weight: 600;
`;

export const CollapseableInfoContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px 18px;

  & > :last-child {
    border-bottom: 0;
  }
`;

export const CollapseableInfoContentRow = styled.div`
  display: flex;
  justify-content: space-between;
  color: #2b2b2b;
  font-size: 12px;
`;

export const CollapseableInfoSubHeader = styled.div`
  display: flex;
  justify-content: space-between;
  color: #2b2b2b;
  color: #787573;
  text-transform: uppercase;
  padding: 14px 18px;
  margin: 0 -18px;
  border-bottom: 1px solid #e6e6e6;
`;
