export const WarningIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_394_159)">
                <path d="M8.00162 8.5273C7.7104 8.5273 7.47432 8.29122 7.47432 8L7.47432 4.30889C7.47432 4.01767 7.7104 3.78158 8.00162 3.78158C8.29284 3.78158 8.52892 4.01767 8.52892 4.30889L8.52892 8C8.52892 8.29122 8.29284 8.5273 8.00162 8.5273Z" fill="#9E3F00" />
                <path d="M8.00162 10.1092C7.41918 10.1092 6.94702 10.5814 6.94702 11.1638C6.94702 11.7463 7.41918 12.2184 8.00162 12.2184C8.58406 12.2184 9.05623 11.7463 9.05623 11.1638C9.05623 10.5814 8.58406 10.1092 8.00162 10.1092Z" fill="#9E3F00" />
                <path fillRule="evenodd" clipRule="evenodd" d="M0.281542 7.25428L7.22694 0.308887C7.63879 -0.102962 8.30652 -0.102962 8.71837 0.308886L15.6638 7.25428C16.0756 7.66613 16.0756 8.33387 15.6638 8.74572L8.71837 15.6911C8.30653 16.103 7.63879 16.103 7.22694 15.6911L0.281543 8.74572C-0.130305 8.33387 -0.130306 7.66613 0.281542 7.25428ZM1.02726 8L7.97266 1.0546L14.9181 8L7.97266 14.9454L1.02726 8Z" fill="#9E3F00" />
            </g>
            <defs>
                <clipPath id="clip0_394_159">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}