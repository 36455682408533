import axios from "axios";

export async function fetchAttachmentsByFlowId(flowId: string) {
  const response = axios
    .get(`/api/flow/flows/${flowId}/attachments`)
    .then((response) => response.data);
  return response;
}

export async function fetchAttachmentByKey(
  flowId: string,
  attachmentKey: string,
) {
  return axios
    .get(`/api/flow/flows/${flowId}/attachments/${attachmentKey}`)
    .then((response) => response.data);
}

export async function downloadAttachment(
  flowId: string,
  attachmentKey: string,
  fileType: string,
) {
  const response = await axios.get(
    `/api/flow/flows/${flowId}/attachments/${attachmentKey}/download`,
    {
      responseType: "arraybuffer",
    },
  );

  let blob;
  // All PDFs we get from the Leabanks DGS (document generation service) are actually base64 encoded text files.
  // therefore, we need to make sure that the caseworker downloads an encoded + converted file, and not a decoded one.
  if (fileType === "pdf") {
    console.log("PDF file detected, converting to base64");
    const isEncoded = isBase64(response.data);
    if (isEncoded) {
      const base64EncodedFile = response.data;

      // Convert the base64 encoded file to a binary format
      const binaryFile = Buffer.from(base64EncodedFile, "base64");

      // Decode the binary file as a UTF-8 string
      const base64EncodedString = binaryFile.toString("utf-8");
      const decodedString = atob(base64EncodedString);

      // Convert the decoded string to a Uint8Array
      const uint8Array = new Uint8Array(decodedString.length);
      for (let i = 0; i < decodedString.length; ++i) {
        uint8Array[i] = decodedString.charCodeAt(i);
      }

      // Convert back to downloadable PDF.
      blob = new Blob([uint8Array], { type: "application/pdf" });
    } else {
      blob = new Blob([response.data], { type: "application/pdf" });
    }
  } else {
    // Otherwise, just download it as is.
    blob = new Blob([response.data], { type: "application/octet-stream" });
  }

  // Logic for actually downloading the file in the browser.
  const temporaryUrl = URL.createObjectURL(blob);
  return temporaryUrl;
}

function isBase64(str: string): boolean {
  if (typeof str !== "string") {
    return false;
  }

  // Length check
  if (str.length % 4 !== 0) {
    return false;
  }

  // Character set check
  const base64Regex: RegExp =
    /^(?:[A-Z0-9+\/]{4})*(?:[A-Z0-9+\/]{2}==|[A-Z0-9+\/]{3}=)?$/i;
  if (!base64Regex.test(str)) {
    return false;
  }

  // Decoding test
  try {
    atob(str);
  } catch (e) {
    return false;
  }

  return true;
}
