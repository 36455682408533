import { MasonryGridContainer, MasonryGridItem } from "./styles";
import type { MasonryGridProps } from "./types";

/**
 * Renders a masonry grid component.
 * @component
 * @param {Object} props - The component props.
 * - childrens - The children components.
 * - breakpointCols - The breakpoint columns [optional].
 * - style - The component style [optional].
 * @returns  {JSX.Element} The rendered component.
 */
export const MasonryGrid = (props: MasonryGridProps) => {
  const breakpointCols = props.breakpointCols ?? {
    default: 2,
  };

  return (
    <MasonryGridContainer
      breakpointCols={breakpointCols}
      className="my-masonry-grid"
      columnClassName="my-masonry-grid_column"
      style={props.style}
    >
      {props.childrens.map((row, index) => (
        <MasonryGridItem key={index}>{row.component}</MasonryGridItem>
      ))}
    </MasonryGridContainer>
  );
};
