import React from "react";
import type { IconProps } from "./types";

export const Collapse = (props: IconProps) => (
  <svg
    onClick={props.onClick}
    style={{ cursor: "pointer" }}
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.70312 8.09082C1.70312 7.81468 1.92698 7.59082 2.20312 7.59082H15.2031C15.4793 7.59082 15.7031 7.81468 15.7031 8.09082C15.7031 8.36696 15.4793 8.59082 15.2031 8.59082H2.20312C1.92698 8.59082 1.70312 8.36696 1.70312 8.09082Z"
      fill="#1E1E1E"
    />
  </svg>
);
