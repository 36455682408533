import styled from 'styled-components';
import Masonry from 'react-masonry-css';

export const MasonryGridContainer = styled(Masonry)`
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  
  width: auto;
  gap: 16px;
  padding: 32px;
  max-height: 100%;
  overflow-y: auto;
`;

export const MasonryGridItem = styled.div`
  break-inside: avoid-column;
  padding-bottom: 16px;
`;