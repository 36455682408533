import styled from "styled-components";

export const StyledLink = styled.div`
    color: #1B4DF5;
    text-decoration: none;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
    `;

export const BorderBottom = styled.div<{ hasPadding: boolean, hasBorderBottom: boolean }>`
    border-bottom: ${(props) => props.hasBorderBottom ? "1px solid #d4dcec" : "none"};
    display: flex;
    justify-content: space-between;
    padding: ${(props) => props.hasPadding ? "0 10px" : "0"};
    align-items: flex-start;
    
    `;

export const CaseInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    color: "#1e1e1e";
    `;

export const InfoWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
    `;

export const InfoText = styled.div<{ hasTextRight: boolean }>`
    margin: 0.5em;
    color: #1e1e1e;
    text-align: ${(props) => props.hasTextRight ? "right" : "left"};
    `;

export const WarningContainer = styled.div`
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    align-items: center;
    background-color: #FFF0CB;
    padding: 0px 8px;
    border-radius: 12px;
    height: 24px;
    color: #9E3F00;
    `;

export const WarningContainerRect = styled.div`
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    background-color: #FFF0CB;
    padding: 8px 8px;
    color: #9E3F00;
    border-radius: 8px;
    `;

export const PaddingContainer = styled.div`
    padding: 0 28px;
    `;

export const Title = styled.div`
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 1rem;
        color: #1e1e1e;
        `;

export const LinkContainer = styled.div`
display: flex;
justify-content: space-between;
gap: 1rem;
padding: 10px 10px;
align-items: center;
`;

export const LinkWrapper = styled.div`
display: flex;
gap: 1rem;
margin: 0px 0.5rem;
`;

export const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;