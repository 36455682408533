import React from "react";
import { Icons } from "@flow/icons";
import { Collapsible } from "@flow/components";
import { CM1RawData, DecisionSectionWrapper } from "../styles";
import { UsertaskButtons, UsertaskDecision, UsertaskInternalComment, UsertaskApplicantInfo } from "../UsertaskComponents";
import CommonUserTask from "../CommonUserTask";
import { CustomInfoSectionWrapper, InfoSectionPersonInfoHeader, InfoSectionPersonInfoItem, InfoSectionPersonInfoItemWrapper, InfoSectionPersonInfoWrapper, InfoSectionPersonsWrapper, InfoSectionWarningText } from "./styles";

const ManualPepReview = (flow: any) => {
  //PEP review comment
  const [comment, setComment] = React.useState(flow.task?.data?.pepComment ?? "");
  //PEP review decision
  const [decision, setDecision] = React.useState(flow.task?.data?.pepDecision ?? null);

  //If sent from overview we need to handle things a bit differently
  const isFromOverview = flow?.flow?.caseData !== undefined;

  //Data variable
  const data = isFromOverview ? flow?.flow?.caseData?.data : flow.flow?.data;

  //Check if caseworker task is active
  const haveTask = data?.queues ? data?.queues["manual-pep-review"] : false;

  // Agent API Data
  const applicant = data?.agentApiApplication?.applicant;
  const applicantFirstName = applicant?.firstName ?? "-";
  const applicantLastName = applicant?.lastName ?? "-";
  const applicantGender = applicant?.gender ?? "-";
  const applicantMaritalStatus = applicant?.maritalStatus ?? "-";
  const applicantNIF = applicant?.nationalId ?? "-";

  // CM1 Data
  const cm1Data = data?.integrations.cm1;
  const cm1ScreenResult = cm1Data?.response?.ScreenResult[0];

  const isChecked = cm1ScreenResult?.Checked;
  const isPEP = cm1ScreenResult?.IsPEP;
  const isSanctioned = cm1ScreenResult?.IsSanction;
  const typeOfHit = isPEP && isSanctioned ? "Both PEP & Sanction" :
    isPEP ? "PEP" :
      isSanctioned ? "Sanction" :
        null;

  const riskData = cm1Data?.risk;
  const riskScore = riskData?.RiskScore ?? "-";
  const riskGroup = riskData?.RiskGroup ?? "-";

  const options = [
    { value: "trueFalsePositive", label: "Continue Loan Application (False positive)" },
    { value: "truePep", label: "Continue Loan Application (PEP/RCA)" },
    { value: "false", label: "Reject Loan Application" },
  ];

  //Renders info about the applicant
  const renderPersonInfo = (flaggedByCm1: boolean) => {
    if (flaggedByCm1) {
      return <>
        <InfoSectionPersonInfoItemWrapper style={{ borderTop: "none" }}>
          <InfoSectionPersonInfoItem>ID</InfoSectionPersonInfoItem>
          <InfoSectionPersonInfoItem>{applicantNIF}</InfoSectionPersonInfoItem>
        </InfoSectionPersonInfoItemWrapper>
        <InfoSectionPersonInfoItemWrapper>
          <InfoSectionPersonInfoItem>Checked</InfoSectionPersonInfoItem>
          <InfoSectionPersonInfoItem style={{ display: "flex", alignItems: "center" }}>{isChecked ? <>Yes <Icons.ErrorCircle /></> : <>No <Icons.SuccessCircle /></>}</InfoSectionPersonInfoItem>
        </InfoSectionPersonInfoItemWrapper>
        <InfoSectionPersonInfoItemWrapper>
          <InfoSectionPersonInfoItem>Type of hit</InfoSectionPersonInfoItem>
          <InfoSectionPersonInfoItem>{typeOfHit}</InfoSectionPersonInfoItem>
        </InfoSectionPersonInfoItemWrapper>
        <InfoSectionPersonInfoItemWrapper>
          <InfoSectionPersonInfoItem>Name</InfoSectionPersonInfoItem>
          <InfoSectionPersonInfoItem>{applicantLastName}</InfoSectionPersonInfoItem>
        </InfoSectionPersonInfoItemWrapper>
        <InfoSectionPersonInfoItemWrapper>
          <InfoSectionPersonInfoItem>Hit rating</InfoSectionPersonInfoItem>
          <InfoSectionPersonInfoItem>{riskScore}</InfoSectionPersonInfoItem>
        </InfoSectionPersonInfoItemWrapper>
        <InfoSectionPersonInfoItemWrapper>
          <InfoSectionPersonInfoItem>Risk group</InfoSectionPersonInfoItem>
          <InfoSectionPersonInfoItem>{riskGroup}</InfoSectionPersonInfoItem>
        </InfoSectionPersonInfoItemWrapper>
        <InfoSectionPersonInfoItemWrapper>
          <InfoSectionPersonInfoItem>Gender</InfoSectionPersonInfoItem>
          <InfoSectionPersonInfoItem>{applicantGender}</InfoSectionPersonInfoItem>
        </InfoSectionPersonInfoItemWrapper>
        <InfoSectionPersonInfoItemWrapper>
          <InfoSectionPersonInfoItem>Marital status</InfoSectionPersonInfoItem>
          <InfoSectionPersonInfoItem>{applicantMaritalStatus}</InfoSectionPersonInfoItem>
        </InfoSectionPersonInfoItemWrapper>
      </>;
    }

    return <>
      <InfoSectionPersonInfoItemWrapper style={{ borderTop: "none" }}>
        <InfoSectionPersonInfoItem>Matches in list</InfoSectionPersonInfoItem>
        <InfoSectionPersonInfoItem style={{ display: "flex", alignItems: "center" }}>No <Icons.SuccessCircle /></InfoSectionPersonInfoItem>
      </InfoSectionPersonInfoItemWrapper>
    </>;
  };

  //Renders the decision section
  const renderDecisionSection = (activeTask: boolean) => {
    if (activeTask) {
      return <DecisionSectionWrapper>
        {UsertaskApplicantInfo(applicant)}
        {UsertaskDecision(
          decision,
          (e) => setDecision(e.target.value),
          options,
        )}
        {UsertaskInternalComment(
          comment,
          (e) => setComment(e.target.value),
        )}
        {UsertaskButtons(
          decision,
          () => flow.complete({
            continueApplication: decision !== "false",
            isPepHitFalsePositive: decision === "trueFalsePositive",
            isPEPorRCA: decision !== "trueFalsePositive",
            pepReviewComment: comment,
          }),
          () => flow.save({ pepComment: comment, pepDecision: decision }),
        )}
      </DecisionSectionWrapper>;
    }

    return null;
  };

  const manualPepContent = () => {
    return (
      <>
        <CustomInfoSectionWrapper>
          {typeOfHit === null || isFromOverview ? null :
            <InfoSectionWarningText>
                Hit on PEP or sanctions list. Must be clarified by the person responsible for money laundering
            </InfoSectionWarningText>
          }
          <InfoSectionPersonsWrapper>
            <InfoSectionPersonInfoWrapper>
              <InfoSectionPersonInfoHeader>
                <div style={{ marginLeft: "20px", display: "flex" }}><Icons.UserAvatar /></div>
                <b style={{ marginLeft: "10px" }}>{applicantFirstName + " " + applicantLastName}</b>
              </InfoSectionPersonInfoHeader>
              {renderPersonInfo(typeOfHit !== null)}
            </InfoSectionPersonInfoWrapper>
          </InfoSectionPersonsWrapper>
          <Collapsible  header="Raw CM1 Data">
            <CM1RawData>
              <pre> {JSON.stringify(cm1Data, null, 4)}</pre>
            </CM1RawData>
          </Collapsible>
        </CustomInfoSectionWrapper>
        {isFromOverview ? null : renderDecisionSection(haveTask)}
      </>
    );
  };

  return (
    CommonUserTask(manualPepContent())
  );
};



export default ManualPepReview;
