import styled from "styled-components";
import type { ReactNode } from "react";

interface ChevronProps {
    direction: DirectionType;
}
type DirectionType = "top" | "right" | "bottom" | "left";

interface TitleContainerProps {
    isExpanded: boolean | undefined;
}

export const Chevron = styled.div<ChevronProps>`
    border-style: solid;
    border-width: 0.1rem 0.1rem 0 0;
    height: 0.4rem;
    width: 0.4rem;
    color: #1B4DF5;
    translate: transform(-50%, -50%);
    transition: all 0.25s ease-in-out;
    transform: ${(p) => p.direction === "top" && "rotate(-45deg)"};
    transform: ${(p) => p.direction === "right" && "rotate(45deg)"};
    transform: ${(p) => p.direction === "bottom" && "rotate(135deg)"};
    transform: ${(p) => p.direction === "left" && "rotate(-135deg)"};
  `;

export const Container = styled.div`
  border: 1px solid #d4dcec;
  border-radius: 8px;
  overflow: hidden;
  background-color: #ffffff;
  min-width: 650px;
`;

export const Title = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    font-size: 14px;
    font-weight: 600;
    line-height: 19.6px;
    cursor: pointer;
    width: 100%;
    border-radius: 0.5rem;
  `;

export const ContentWrapper = styled.div<{ maxHeight: number }>`
    max-height: ${(p) => `${p.maxHeight}px`};
    transition: max-height 0.25s ease-in-out;
    overflow: hidden;
  `;

export const Content = styled.div`
    padding: 0 0 1rem;
    color: rgba(0, 0, 0, 0.75);
  `;

export const TitleContainer = styled.div<TitleContainerProps>`
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    background-color: #f8f9fc;
    color: #1e1e1e;
    border-bottom: ${(p) => (p.isExpanded ? "1px solid #d4dcec" : "none")};
  `;