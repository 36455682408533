import type { IconProps } from "./types";

export const ChevronDown = (props: IconProps) => {
  return (
    <svg
      onClick={props.onClick}
      style={{ cursor: "pointer" }}
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="23"
      viewBox="0 0 21 23"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.8092 9.85789L13.5592 8.56152L10.6425 11.7699L7.72587 8.56152L6.47587 9.85789L10.6425 14.5199L14.8092 9.85789Z"
        fill="#2969FF"
      />
    </svg>
  );
};
