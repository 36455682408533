import { AxiosInstance } from "axios";
import NodeCache from "node-cache";

const myCache = new NodeCache();

type GetTokenFunction = () => Promise<AxiosInstance | null | undefined>;

export const getAndCacheToken = async (tokenName: string, getFunction: GetTokenFunction) => {
  let cachedToken = myCache.get(tokenName);
  // Authorization token needed to authenticate the AgentAPI to FlowProcess.
  if (!cachedToken) {
    cachedToken = await getFunction();
    myCache.set(tokenName, cachedToken, 3600);
  }
  return cachedToken;
};
