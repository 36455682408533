import { DecisionSectionWrapper, DecisionSectionDivWrapper } from "../styles";
import React, { useState } from "react";
import { UsertaskButtons, UsertaskApplicantInfo, renderMessage, UsertaskTextArea, UsertaskSelect } from "../UsertaskComponents";
import { Checkboxes } from "@flow/forms";
import { SecondaryButton } from "@flow/buttons";
import { Icons } from "@flow/icons";
import { callApplicant } from "../../../services/itx.service";
import { communicationTemplates } from "../../../templates/emailAndSmsTemplates";
import { isStringNullOrEmpty } from "../../../utils/helperFunctions";
import { replaceVariablesInTemplate } from "../../../templates/templateHelpers";
import CommonUserTask from "../CommonUserTask";
import { List, ListItem, SMSConversationWrapper, CallSectionWrapper, IconWrapper } from "./styles";

const ContactApplicant = (flow: any) => {
  //Fields for sms and mail
  const [to, setTo] = useState(flow.task?.data?.smsTo ?? "");
  const [template, setTemplate] = useState(flow.task?.data?.template ?? "");
  const [message, setMessage] = useState(flow.task?.data?.message ?? "");
  const [error, setError] = useState<any>(null);

  //Checkbox for sms and mail
  const [mailAndOrSms, setMailAndOrSms] = useState(flow.task?.data?.mailAndOrSms ?? []);

  //Dropdown for call
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  //Data variables
  const data = flow.flow?.data;
  const applicant = data?.agentApiApplication?.applicant;

  //Get applicants and merge them to one array
  let applicants = [applicant];
  if (data?.agentApiApplication?.coApplicant !== undefined) applicants.push(data?.agentApiApplication?.coApplicant);

  const toOptions = [
    {
      label: "Choose a recipient",
      value: "",
    },
    {
      label: `${applicant?.firstName} ${applicant?.lastName}`,
      value: "applicant",
    },
    ...(applicants.length > 1
      ? [
        {
          label: `${data?.agentApiApplication?.coApplicant?.firstName} ${data?.agentApiApplication?.coApplicant?.lastName}`,
          value: "coApplicant",
        },
        {
          label: "Both",
          value: "both",
        },
      ]
      : []),
  ];

  const gotOffer = data?.offer || data?.softOffer;
  const templateOptions = Object.keys(communicationTemplates).map((key) => ({
    label: communicationTemplates[key].templateName,
    value: key,
    disabled: communicationTemplates[key].variables.includes("signinglink") && !gotOffer,
  }));

  templateOptions.unshift({
    label: "No template / Free text",
    value: "",
    disabled: false,
  });

  //Get mails with key string mailCommunication from communication
  let smsSent = data?.integrations?.itx?.smsSent ?? [];
  let callsMade = data?.integrations?.itx?.calls ?? [];
  let mailSent = data?.communication?.filter((mail: any) => mail.key === "mailCommunication") ?? [];

  //Merge sms, calls and mail
  let smsAndMailSent = [...smsSent, ...callsMade, ...mailSent].sort((a, b) => new Date(Date.parse(a.timestamp)).getTime() - new Date(Date.parse(b.timestamp)).getTime());

  //Function to check if checkbox is checked
  const isChecked = (value: any) => {
    return mailAndOrSms.includes(value);
  };

  //Function to handle checkbox change
  const handleCheckboxChange = (e: any) => {
    const { value } = e.target;
    if (isChecked(value)) {
      setMailAndOrSms(mailAndOrSms.filter((item: any) => item !== value));
    } else {
      setMailAndOrSms([...mailAndOrSms, value]);
    }
  };

  //Const to check if sms and mail is checked
  const smsChecked = isChecked("sms");
  const mailChecked = isChecked("mail");

  const smsOrMailChecked = smsChecked || mailChecked;
  const messageOrTemplate = (isStringNullOrEmpty(template) && !isStringNullOrEmpty(message)) || !isStringNullOrEmpty(template);

  const canComplete = smsOrMailChecked && !isStringNullOrEmpty(to) && messageOrTemplate ? true : null;

  const handleCalling = async (number: string) => {
    try {
      await callApplicant(number);
      flow.trigger({ callTo: number, isMail: false, isSms: false, isCall: true });
    } catch (err) {
      setError(err);
      setIsDropdownVisible(false);
    }
  };

  const callButtonClicked = () => {
    //If we don't have a coapplicant, we can just call the applicant
    if (applicants.length === 1)
      handleCalling(applicant?.mobileNumber);
    else
      //If we have a coapplicant, we need to show a dropdown with the applicant and coapplicant
      setIsDropdownVisible(!isDropdownVisible);
  };

  const renderDropdown = () => {
    return (
      <List>
        {applicants.map((listedApplicant, index) => {
          return (
            <ListItem
              key={"Applicants-" + index}
              onClick={() => handleCalling(listedApplicant?.mobileNumber)}
            >
              {listedApplicant?.firstName} {listedApplicant?.lastName}
            </ListItem>
          );
        })}
      </List>
    );
  };

  const flowTrigger = () => {
    // Check if both smsChecked and mailChecked are false
    if (!smsChecked && !mailChecked) return null;

    let templateMessage = null;
    // Check if template is selected
    if (smsOrMailChecked && !isStringNullOrEmpty(template)) {
      // Get template from communicationTemplates
      const templateObject = communicationTemplates[template];
      templateMessage = replaceVariablesInTemplate(templateObject.text, templateObject.variables, data);
    }

    // Create options object with message, isMail, isSms, and isCall properties
    const options = {
      message: templateMessage ?? message,
      to,
      isMail: mailChecked,
      isSms: smsChecked,
      isCall: false,
    };

    // return null;
    return flow.trigger(options);
  };

  const communicationContent = () => {
    return (
      <>
        <SMSConversationWrapper>
          {smsAndMailSent?.map((message, index) => {
            return (
              renderMessage(message, index)
            );
          })}
        </SMSConversationWrapper>
        <DecisionSectionWrapper>
          {UsertaskApplicantInfo(applicant)}

          <CallSectionWrapper>
            <SecondaryButton
              style={{ display: "flex", alignItems: "center", overflow: "hidden" }}
              onClick={callButtonClicked}
            >
              Click to call <IconWrapper><Icons.Phone style={{ transform: "rotate(-125deg)" }} /></IconWrapper>
            </SecondaryButton>
            {isDropdownVisible ? renderDropdown() : null}
          </CallSectionWrapper>
          <DecisionSectionDivWrapper>
            <label style={{ marginBottom: "10px" }}>Send SMS and/or Mail</label>
            <Checkboxes
              name="checkboxes"
              options={[
                { label: "SMS", value: "sms" },
                { label: "Mail", value: "mail" },
              ]}
              onChange={(e: any) => {
                handleCheckboxChange(e);
              }}
              checkedValues={mailAndOrSms}
            />
          </DecisionSectionDivWrapper>
          {smsOrMailChecked ? UsertaskSelect("To", toOptions, to, setTo) : null}
          {smsOrMailChecked ? UsertaskSelect("Template", templateOptions, template, setTemplate, { marginTop: "10px" }) : null}
          {smsOrMailChecked && (!template || template.length === 0) ? UsertaskTextArea(message, setMessage, "Message") : null}
          {UsertaskButtons(
            canComplete,
            flowTrigger,
            () => flow.save({ smsTo: to, template: template, message: message, mailAndOrSms: mailAndOrSms }),
          )}
          {!error ? null :
            <DecisionSectionDivWrapper style={{ display: "flex", backgroundColor: "#e57373", justifyContent: "center", boxShadow: "0px 15px 40px rgb(184 184 184 / 20%), 0px 5px 10px rgb(184 184 184 / 5%)", borderRadius: "5px", padding: "10px" }}>
              <span style={{ display: "flex", alignItems: "center", gridGap: "5px", color: "white", fontSize: "12px" }}>Was not able to make the call. Make sure you are logged on ITX before trying again.</span>
            </DecisionSectionDivWrapper>
          }
        </DecisionSectionWrapper>
      </>
    );
  };

  return (
    CommonUserTask(communicationContent())
  );
};

export default ContactApplicant;
