import type { IconProps } from "./types";

export const ChevronRight = (props: IconProps) => {
  return (
    <svg
      onClick={props.onClick}
      style={{ cursor: "pointer" }}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.14645 2.14645C4.95118 2.34171 4.95118 2.65829 5.14645 2.85355L10.7929 8.5L5.14645 14.1464C4.95118 14.3417 4.95118 14.6583 5.14645 14.8536C5.34171 15.0488 5.65829 15.0488 5.85355 14.8536L11.8536 8.85355C12.0488 8.65829 12.0488 8.34171 11.8536 8.14645L5.85355 2.14645C5.65829 1.95118 5.34171 1.95118 5.14645 2.14645Z"
        fill="#1E1E1E"
      />
    </svg>
  );
};
