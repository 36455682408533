// Button.tsx
import type React from "react";
import { TransparentButton } from "@flow/buttons";
import type { TabButtonProps } from "./types";
import { StyledTabButton } from "./styles";

export const TabButton = (props: TabButtonProps) => {
  return (
    <StyledTabButton
      label={props.label}
      component={TransparentButton}
      disabled={props.disabled}
      selected={props.selected}
      onClick={props.onClick}
    >
      {props.label}
    </StyledTabButton>
  );
};
