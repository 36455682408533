import React, { createContext, useContext } from "react";
import type { TaskProps } from "../types/TaskProps";

type FlowContextType = TaskProps & {
  isReevaluating?: boolean;
  errors?: string[];
};

const FlowContext = React.createContext<FlowContextType | null>(null);

function useFlowContext(): FlowContextType {
  const context = useContext(FlowContext);
  if (!context) {
    throw new Error("useFlowContext must be used within a FlowContextProvider");
  }
  return context;
}
export { useFlowContext, FlowContext };

const flowContext = createContext({});
export const FlowContextProvider = flowContext.Provider;
