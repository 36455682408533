import { FlowData } from "@flow";

export function replaceVariablesInTemplate(templateText: string, variablesToReplace: string[], data: FlowData) {
  let modifiedTemplate = templateText;
  for (const variable of variablesToReplace) {
    if (variable === "firstname") {
      modifiedTemplate = modifiedTemplate.replace(/{{firstname}}/g, data?.agentApiApplication?.applicant?.firstName);
    } else if (variable === "signinglink") {
      let offer = data?.offer;
      if (!offer) {
        offer = data?.softOffer;
      }
      modifiedTemplate = modifiedTemplate.replace(/{{signinglink}}/g, offer?.signingLink);
    }
    // Add more else if statements for other variables to replace
  }
  return modifiedTemplate;
}
