import { withLDProvider } from "launchdarkly-react-client-sdk";
import { getUserInfoFromSessionStorage } from "../utils/userHelper";

type CustomWindow = {
  stacc_env?: any;
};


export function getLaunchDarklyConfig() {
  const customWindow: Window & CustomWindow = window;
  const clientSideID = customWindow.stacc_env.featureToggles[0];
  const profile = getUserInfoFromSessionStorage();
  if (!profile || !profile.username)
    return withLDProvider({
      clientSideID: clientSideID,
      user: {
        key: "flow-portal",
        name: "Flow Portal",
        email: "",
      },
    });

  return withLDProvider({
    clientSideID: clientSideID,
    user: {
      key: profile.username,
      name: profile.name,
      email: profile.username,
      custom: {
        service: "flow-portal",
        roles: profile.roles,
      },
    },
  });
}
