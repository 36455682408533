import { NormalInfoWrapper } from "../../views/tasks/styles";

export const OverviewRow = (props: {
    label: string;
    value: any;
    isLast?: boolean;
}) => {
    const { label, value, isLast } = props;

    return (
        <NormalInfoWrapper key={label} isLast={isLast}>
            <div>{label}</div>
            <div>{value}</div>
        </NormalInfoWrapper>
    );
};
