import React from "react";
import { getLaunchDarklyConfig } from "./config/launchdarkly-config";
import { Portal } from "./config/case-manager";
function App() {
  return (
    <>{Portal()}</>
  );
}

export default getLaunchDarklyConfig()(App);
