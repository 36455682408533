import { Colors } from "@flow/style";
import React from "react";
import { InfoSectionWrapper, DecisionSectionWrapper, DecisionSectionDivWrapper } from "../styles";
import { UsertaskButtons, UsertaskDecision, UsertaskInternalComment, UsertaskApplicantInfo } from "../UsertaskComponents";
import CommonUserTask from "../CommonUserTask";
import { InfoSectionLessThanThreeWrapper, InfoSectionMoreThanTwoWrapper, InfoSectionMoreThanTwoSelectSection, InfoSectionTransactionSelectWrapper, InfoSectionTransactionSelect, InfoSectionTransactions, InfoSectionAccountWrapper, InfoSectionAccountInfoWrapper, InfoSectionAccountInfoSection, InfoSectionAccountInfoBold, InfoSectionTransactionInfoWrapper, InfoSectionTransactionInfoDate, InfoSectionTransactionDetails, InfoSectionTransactionDetailsPadding, DecisionSectionDivInfoWrapper } from "./styles";

// Internationalization formatting options.
const currencyFormatOptions: Intl.NumberFormatOptions = { style: "currency", currency: "EUR" };
const dateFormatOption: Intl.DateTimeFormatOptions = {
  dateStyle: "full",
  timeStyle: "short",
  hour12: false,
};

const ManualApplicationReview = (flow: any) => {
  //Application review comment
  const [comment, setComment] = React.useState(flow.task?.data?.applicationComment ?? "");
  //Application review decision
  const [decision, setDecision] = React.useState(flow.task?.data?.applicationDecision ?? null);

  //If sent from overview we need to handle things a bit differently
  const isFromOverview = flow?.flow?.caseData !== undefined;

  //Data variable
  const data = isFromOverview ? flow?.flow?.caseData?.data : flow.flow?.data;

  //Check if caseworker task is active
  const haveTask = data?.queues ? data?.queues["manual-payout-approval"] : false;

  const applicant = data?.agentApiApplication?.applicant;
  const psd2Data = applicant?.psD2Data;
  const accounts = psd2Data?.accounts;
  const transactions = psd2Data?.transactions;

  // Applicant Data
  const applicantFirstName = applicant?.firstName || "N/A";
  const applicantLastName = applicant?.lastName || "N/A";
  const appliedAmount = data?.offer?.finalLoanAmount || 0;

  //Get and set account selected when we have more than 2 accounts
  const [activeAccount1, setActiveAccount1] = React.useState(accounts && accounts.length > 2 ? data?.agentApiApplication?.agentId === "lendo" ? accounts[0].account_number : accounts[0].accountId : "");
  const [activeAccount2, setActiveAccount2] = React.useState(accounts && accounts.length > 2 ? data?.agentApiApplication?.agentId === "lendo" ? accounts[1].account_number : accounts[1].accountId : "");

  //Variables to get the full accounts data when ID is provided in state
  const acc1 = activeAccount1 && activeAccount1.length > 0 ? accounts.find((acc: any) => data?.agentApiApplication?.agentId === "lendo" ? acc.account_number == activeAccount1 : acc.accountId === activeAccount1) : null;
  const acc2 = activeAccount2 && activeAccount2.length > 0 ? accounts.find((acc: any) => data?.agentApiApplication?.agentId === "lendo" ? acc.account_number == activeAccount2 : acc.accountId === activeAccount2) : null;

  //Renders the account section
  const renderInfoSection = () => {
    return (
      <InfoSectionWrapper>
        {!accounts || accounts.length === 0 ? null :
          accounts.length < 3 ?
            <InfoSectionLessThanThreeWrapper>
              {accounts.map((acc: any, index: number) => {
                return (
                  renderAccount(acc, index)
                );
              })}
            </InfoSectionLessThanThreeWrapper> :
            <InfoSectionMoreThanTwoWrapper>
              <InfoSectionMoreThanTwoSelectSection>
                <InfoSectionTransactionSelectWrapper>
                    Account
                  <InfoSectionTransactionSelect onChange={(e) => setActiveAccount1(e.target.value)} defaultValue={activeAccount1}>
                    {accounts.map((acc: any, index: number) => {
                      if (!acc.account_number && !acc.accountId)
                        return null;

                      return (
                        <option key={index}>{data?.agentApiApplication?.agentId === "lendo" ? acc.account_number : acc.accountId}</option>
                      );
                    })}
                  </InfoSectionTransactionSelect>
                </InfoSectionTransactionSelectWrapper>
                <InfoSectionTransactionSelectWrapper>
                    Account
                  <InfoSectionTransactionSelect onChange={(e) => setActiveAccount2(e.target.value)} defaultValue={activeAccount2}>
                    {accounts.map((acc: any, index: number) => {
                      if (!acc.account_number && !acc.accountId)
                        return null;

                      return (
                        <option key={index}>{data?.agentApiApplication?.agentId === "lendo" ? acc.account_number : acc.accountId}</option>
                      );
                    })}
                  </InfoSectionTransactionSelect>
                </InfoSectionTransactionSelectWrapper>
              </InfoSectionMoreThanTwoSelectSection>
              <InfoSectionTransactions>
                {acc1 ? renderAccount(acc1, "account1") : null}
                {acc2 ? renderAccount(acc2, "account2") : null}
              </InfoSectionTransactions>
            </InfoSectionMoreThanTwoWrapper>
        }
      </InfoSectionWrapper>
    );
  };

  //Renders one account
  const renderAccount = (acc: any, key: string | number) => {
    //Lendo data got transactions on the account object
    return (
      <InfoSectionAccountWrapper key={key}>
        <InfoSectionAccountInfoWrapper>
          <InfoSectionAccountInfoSection>
                      IBAN
            <InfoSectionAccountInfoBold>{data?.agentApiApplication?.agentId === "lendo" ? acc.account_number : acc.accountId}</InfoSectionAccountInfoBold>
          </InfoSectionAccountInfoSection>
          <InfoSectionAccountInfoSection style={{ alignItems: "flex-end" }}>
                      Balance
            <InfoSectionAccountInfoBold>{formatExpenseOrIncomeAmount(acc.balance, true)}</InfoSectionAccountInfoBold>
          </InfoSectionAccountInfoSection>
        </InfoSectionAccountInfoWrapper>
        {data?.agentApiApplication?.agentId === "lendo" ?
          <>
            {acc.transactions?.sort((a: any, b: any) => new Date(Date.parse(b.date)).getTime() - new Date(Date.parse(a.date)).getTime()).map((transaction: any, index: number) => {
              return (
                <InfoSectionTransactionInfoWrapper key={index}>
                  <InfoSectionTransactionInfoDate>
                    {new Intl.DateTimeFormat("en-GB", dateFormatOption).format(new Date(Date.parse(transaction.date)))}
                  </InfoSectionTransactionInfoDate>
                  <InfoSectionTransactionDetails>
                    <InfoSectionTransactionDetailsPadding style={{ color: "#000000" }}>{transaction.description}</InfoSectionTransactionDetailsPadding>
                    <InfoSectionTransactionDetailsPadding>{formatExpenseOrIncomeAmount(transaction.transactionClass == "debit" ? transaction.amount * (-1) : transaction.amount)}</InfoSectionTransactionDetailsPadding>
                  </InfoSectionTransactionDetails>
                </InfoSectionTransactionInfoWrapper>
              );
            })}
          </>
          :
          <>
            {transactions?.sort((a: any, b: any) => new Date(Date.parse(b.date)).getTime() - new Date(Date.parse(a.date)).getTime()).reduce((filtered: any[], transaction: any) => {
              if (transaction?.accountId && transaction.accountId === acc.accountId) {
                filtered.push(
                  <InfoSectionTransactionInfoWrapper key={filtered.length}>
                    <InfoSectionTransactionInfoDate>
                      {transaction?.date ? new Intl.DateTimeFormat("en-GB", dateFormatOption).format(new Date(Date.parse(transaction.date))) : "Invalid date"}
                    </InfoSectionTransactionInfoDate>
                    <InfoSectionTransactionDetails>
                      <InfoSectionTransactionDetailsPadding style={{ color: "#000000" }}>{transaction?.description ?? "Invalid description"}</InfoSectionTransactionDetailsPadding>
                      <InfoSectionTransactionDetailsPadding>{formatExpenseOrIncomeAmount(transaction.transactionClass == "debit" ? transaction.amount * (-1) : transaction.amount)}</InfoSectionTransactionDetailsPadding>
                    </InfoSectionTransactionDetails>
                  </InfoSectionTransactionInfoWrapper>);
              }
              return filtered;
            }, []).map((element: any) => element)}
          </>
        }
      </InfoSectionAccountWrapper>
    );
  };

  //Renders the decision section
  const renderDecisionSection = (activeTask: boolean) => {
    if (!activeTask) return null;

    return (
      <DecisionSectionWrapper>
        {UsertaskApplicantInfo(applicant)}
        <DecisionSectionDivWrapper>
          <DecisionSectionDivInfoWrapper>
            <p>Application name</p>
            <p>{applicantFirstName} {applicantLastName}</p>
          </DecisionSectionDivInfoWrapper>
          <DecisionSectionDivInfoWrapper>
            <p>Loan offer</p>
            <p>{formatExpenseOrIncomeAmount(appliedAmount, true)}</p>
          </DecisionSectionDivInfoWrapper>
        </DecisionSectionDivWrapper>
        {UsertaskDecision(
          decision,
          (e) => setDecision(e.target.value == "true"),
        )}
        {UsertaskInternalComment(
          comment,
          (e) => setComment(e.target.value),
        )}
        {UsertaskButtons(
          decision,
          () => flow.complete({ isIncomeVerified: decision, applicationReviewComment: comment }),
          () => flow.save({ applicationComment: comment, applicationDecision: decision }),
        )}
      </DecisionSectionWrapper>
    );
  };

  const manualApplicationReviewContent = () => {
    return (
      <>
        {renderInfoSection()}
        {renderDecisionSection(haveTask && !isFromOverview)}
      </>
    );
  };

  return (
    CommonUserTask(manualApplicationReviewContent())
  );
};

const formatExpenseOrIncomeAmount = (amount: number, dontRenderColors: boolean = false) => {
  if (dontRenderColors) {
    return <>{new Intl.NumberFormat("en-GB", currencyFormatOptions).format(amount)}</>;
  }
  if (amount < 0) {
    // Returns a cell with red text, if the amount is negative in order to indicate that this transaction is an outgoing payment.
    return <div style={{ color: Colors.Ferrari, width: "max-content" }}>{new Intl.NumberFormat("en-GB", currencyFormatOptions).format(amount)}</div>;
  }
  // Returns a cell with green text if otherwise, indicating some sort of incoming payment.
  return <div style={{ color: Colors.Grass }}>{new Intl.NumberFormat("en-GB", currencyFormatOptions).format(amount)}</div>;
};

export default ManualApplicationReview;
