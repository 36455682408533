import { useQuery } from "react-query";
import { LoadingSpinner } from "../Spinner/LoadingSpinner";
import { downloadAttachment } from "../../utils/fetchAttachments";

type FilePreviewProps = {
  attachmentKey: string;
  fileType: string;
  flowId: string;
  colSpan?: number;
};
export function FilePreview({
  attachmentKey,
  fileType,
  flowId,
}: FilePreviewProps) {
  fileType = fileType.toLowerCase();
  const { data: file, isFetching } = useQuery({
    queryKey: ["file", attachmentKey, flowId],
    queryFn: () => downloadAttachment(flowId, attachmentKey, fileType),
  });
  if (isFetching && !file)
    return (
      <div>
        <LoadingSpinner width={24} height={24} />
      </div>
    );
  const imageTypes = ["jpeg", "png", "jpg"];
  const isImage = imageTypes.includes(fileType);
  const isPdf = fileType === "pdf";
  return (
    <div>
      {isImage && <img src={file} alt="file" />}
      {isPdf && (
        <iframe
          style={{ minWidth: "80vw", height: "70vh" }}
          title="file"
          src={`${file}#toolbar=0`}
        >
          Fallback to this
        </iframe>
      )}
    </div>
  );
}
