import styled from "styled-components";
import { FlexWrapper, getColorForEnvironment, InfoSectionWrapper } from "../styles";

export const InfoWrapper = styled(InfoSectionWrapper)`
    overflow: auto;
    //Hide scrollbar
    &::-webkit-scrollbar {
        display: none;
    }
`;

export const InfoSectionApplicantInfoWrapper = styled(FlexWrapper)`
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50px;
  background-color: ${getColorForEnvironment()};
  flex: 0 0 50px;
`;

export const ApplicantsWrapper = styled(FlexWrapper)`
    justify-content: center;
    grid-gap: 20px;
    padding: 25px 60px;
    height: 100%;
    box-sizing: border-box;
    align-items: flex-start;

    @media only screen and (max-width: 850px) {
        flex-direction: column;
        align-items: center;
    }
`;

export const ApplicantWrapper = styled(FlexWrapper)`
    flex-direction: column;
    width: 370px;
    background-color: #FFFFFF;
    border-radius: 5px;
    box-shadow: 0px 15px 40px rgba(184, 184, 184, 0.2), 0px 5px 10px rgba(184, 184, 184, 0.05);
    overflow: auto;
    max-height: 100%;
`;

export const ApplicantInfoWrapper = styled(FlexWrapper)`
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    flex: 0 0 70px;
    border-bottom: 1px solid #E2E2E1;
    padding: 10px 20px;
    flex-basis: auto;
    margin: 0;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.52);

    &:last-child {
    border-bottom: none;
    }
`;

export const ApplicantDetailsPadding = styled.div`
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  overflow-wrap: anywhere;
  max-width: 60%;
`;