const commonTemplate = {
  greeting: "¡Hola, {{firstname}}!\n\n",
  closing: "\n\nSaludos, Lea bank.",
  thanks: "\n\n¡Gracias por elegirnos!",
};

export const communicationTemplates: {
  [key: string]: {
    templateName: string;
    text: string;
    variables: string[];
  };
} = {
  PRE_APPROVAL: {
    templateName: "Pre-approval",
    text: `${commonTemplate.greeting}Tu solicitud de préstamo ha sido preautorizada.\n\nHaz clic en el siguiente enlace para completar tu solicitud: {{signinglink}}${commonTemplate.thanks}${commonTemplate.closing}`,
    variables: ["firstname", "signinglink"],
  },
  SIGNATURE_REMINDER: {
    templateName: "Signature reminder after ID check",
    text: `${commonTemplate.greeting}¡Todo listo!, tu contrato de préstamo está preparado para que lo puedas firmar.\n\nHaz clic en el siguiente enlace para completar tu solicitud: {{signinglink}}${commonTemplate.thanks}${commonTemplate.closing}`,
    variables: ["firstname", "signinglink"],
  },
  APPLICATION_DECLINED: {
    templateName: "Application declined",
    text: `${commonTemplate.greeting}Después de valorar detenidamente toda la información que nos has facilitado, no nos es posible continuar con tu solicitud de préstamo en este momento.${commonTemplate.closing}`,
    variables: ["firstname"],
  },
  APPLICATION_APPROVED_REMINDER: {
    templateName: "Reminder approved application",
    text: `${commonTemplate.greeting}Solo falta unos pocos clics para recibir tu préstamo, no te llevara mucho tiempo.\n\nHaz clic en el siguiente enlace para completar tu solicitud: {{signinglink}}${commonTemplate.thanks}${commonTemplate.closing}`,
    variables: ["firstname", "signinglink"],
  },
  DISBURSEMENT_CONFIRMATION: {
    templateName: "Disbursement confirmation",
    text: `${commonTemplate.greeting}¡Enhorabuena! Ya hemos realizado la transferencia del préstamo a tu cuenta bancaria. ¡Queremos darte la bienvenida a Lea bank!\n\nTu dinero estará disponible entre 1 y 2 días hábiles.${commonTemplate.thanks}${commonTemplate.closing}`,
    variables: ["firstname"],
  },
};
