import React from "react";
import { ContentFlexWrapper, ContentWrapper } from "./styles";

const CommonUserTask = (content: any) => (
  <ContentWrapper style={{ height: "100%" }}>
    <ContentFlexWrapper>
      {content}
    </ContentFlexWrapper>
  </ContentWrapper>
);

CommonUserTask.dislplayName = "CommonUserTask";

export default CommonUserTask;
