import { PrimaryButton, SecondaryButton } from "@flow/buttons";
import { ButtonContainer, StyledButton } from "./styles";
import type { FormButtonProps } from "./types";

/**
 * Renders a form button group.
 * @component
 * @param {Object} props - The component props.
 * - handleComplete - The function to handle the complete button click.
 * - handleSave - The function to handle the save button click.
 * - disabled - Indicates if the buttons should be disabled.
 * - labelPrimary - The label for the primary button.
 * - labelSecondary - The label for the secondary button.
 * @returns {JSX.Element} The rendered component.
 */
export const FormButtons = (props: FormButtonProps) => {
  return (
    <ButtonContainer>
      <StyledButton
        component={PrimaryButton}
        disabled={props.disabled || props?.disablePrimary}
        onClick={props.handleComplete}
        primary
      >
        {props.labelPrimary ?? "Complete"}
      </StyledButton>
      {props.handleSave && (
        <StyledButton
          component={SecondaryButton}
          onClick={props.handleSave}
          disabled={props.disabled || props?.disableSecondary}
        >
          {props.labelSecondary ?? "Save and close"}
        </StyledButton>
      )}
    </ButtonContainer>
  );
};
