import { useState } from "react";
import { camelCaseToWords } from "../../utils/camelCaseToWords";
import { InfoHeader, InfoRow, InfoTitle, InfoValue } from "./styles";
import { Collapse } from "../Icons/Collapse";
import { ChevronDown } from "../Icons/ChevronDown";
import { formatBooleanToText } from "../../utils/component.helper";

/**
 * Renders a component to display information in the form section.
 * @component
 * @param {Object} props - The component props.
 * - context - The context object from the task.
 * - title - The title of the infor section.
 * - collapsable - Indicates if the section should be collapsable.
 * @returns
 * An information section.
 */
export const ListInfo = (props: {
  context: any;
  title: string;
  collapsable?: boolean;
  collapsed?: boolean;
}) => {
  const [collapsed, setCollapsed] = useState(props.collapsed ?? true);
  if (!props.collapsable) {
    return (
      <div style={{ color: "#2B2B2B" }}>
        <InfoTitle>{props.title}</InfoTitle>
        {renderInfo(props.context)}
      </div>
    );
  }
  return (
    <div>
      <InfoHeader
        onClick={() => setCollapsed(!collapsed)}
        style={{ cursor: "pointer" }}
      >
        <div>{props.title}</div>
        {!collapsed ? <Collapse /> : <ChevronDown />}
      </InfoHeader>
      {!collapsed && renderInfo(props.context)}
    </div>
  );
};

const renderInfo = (context: any): any => {
  return Object.keys(context).map((key, index) => {
    if (typeof context[key] === "object") {
      return renderInfo(context[key]);
    }

    return (
      <InfoRow key={index}>
        {camelCaseToWords(key)}
        <InfoValue>
          {typeof context[key] === "boolean"
            ? formatBooleanToText(context[key])
            : context[key]}
        </InfoValue>
      </InfoRow>
    );
  });
};
