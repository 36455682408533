import React from "react";
import PropTypes from "prop-types";
import { PrimaryProgressButton } from "@flow/buttons";
import { RadioButtons, TextArea, Text, Select, Number } from "@flow/forms";
import { formatCurrency } from "../../utils/helperFunctions";
import * as Styles from "./styles";
import { Applicant } from "@flow";

export function UsertaskApplicantInfo(applicant: Applicant, mainApplicant: boolean = true) {
  const applicantFirstName = applicant?.firstName ?? "-";
  const applicantLastName = applicant?.lastName ?? "-";
  const applicantNIF = applicant?.nationalId ?? "-";
  const applicantEmail = applicant?.emailAddress ?? "-";
  const applicantPhone = applicant?.mobileNumber ?? "-";

  return (
    <Styles.DecisionSectionDivWrapper>
      <Styles.DecisionSectionPersonWrapper>
        <Styles.DecisionSectionPersonApplicantWrapper>
          <Styles.DecisionSectionPersonApplicantBold>{mainApplicant ? "MA" : "CA"}</Styles.DecisionSectionPersonApplicantBold>
        </Styles.DecisionSectionPersonApplicantWrapper>
        <Styles.DecisionSectionPersonInfoWrapper>
          <Styles.DecisionSectionPersonInfoDivWrapper>
            <Styles.DecisionSectionPersonApplicantBold>{applicantFirstName} {applicantLastName}</Styles.DecisionSectionPersonApplicantBold>
            {applicantNIF}
          </Styles.DecisionSectionPersonInfoDivWrapper>
          <Styles.DecisionSectionPersonInfoDivWrapper style={{ alignItems: "flex-end" }}>
            <Styles.DecisionSectionPersonInfoAnchor href={"tel:" + applicantPhone}>{applicantPhone}</Styles.DecisionSectionPersonInfoAnchor>
            <Styles.DecisionSectionPersonInfoAnchor href={"mailto:" + applicantEmail}>{applicantEmail}</Styles.DecisionSectionPersonInfoAnchor>
          </Styles.DecisionSectionPersonInfoDivWrapper>
        </Styles.DecisionSectionPersonInfoWrapper>
      </Styles.DecisionSectionPersonWrapper>
    </Styles.DecisionSectionDivWrapper>
  );
}

export function UsertaskDecision(decision: any, setDecision: (e: any) => void, options: any = null) {
  return (
    <Styles.DecisionSectionDivWrapper>
      <b>Decision</b>
      <RadioButtons
        name="myRadio"
        checkedValue={decision}
        onChange={setDecision}
        options={options ?? [
          {
            label: "Continue Loan Application",
            value: true,
          },
          {
            label: "Reject Loan Application",
            value: false,
          },
        ]}
      />
    </Styles.DecisionSectionDivWrapper>
  );
}

export function UsertaskInternalComment(comment: string, setComment: (e: any) => void, title: string = "") {
  return (
    <Styles.DecisionSectionDivWrapper>
      <b>{title ?? ""}</b>
      <TextArea style={{ width: "100%", resize: "none", height: "100px" }}
        name="comment"
        onChange={setComment}
        defaultValue={comment}
      />
    </Styles.DecisionSectionDivWrapper>
  );
}

export function UsertaskSelect(label: string, options: any[], value: any, setValue: (e: any) => void, style: any = {}) {
  return (
    <Styles.DecisionSectionDivWrapper style={{ ...style, marginBottom: "10px" }}>
      <Select
        label={label}
        name={label}
        options={options}
        onChange={(e: any) => setValue(e.target.value)}
        value={value}
      />
    </Styles.DecisionSectionDivWrapper>
  );
}

export function UsertaskTextField(text: string, setText: (e: any) => void, title: string = "Title", placeholder: string = "Text") {
  return (
    <Styles.DecisionSectionDivWrapper style={{ margin: "10px 25px" }}>
      <label style={{ fontSize: "12px" }}>{title}</label>
      <Text
        name="textField"
        onChange={setText}
        defaultValue={text}
        placeholder={placeholder}
      />
    </Styles.DecisionSectionDivWrapper>
  );
}

export const UsertaskNumberInput = (value: any, setValue: ((e: any) => void) | null, title: string = "Loan Amount",  min: number = 0, max: number = Infinity, disabled: boolean = false) => {
  return (
    <Styles.DecisionSectionDivWrapper style={{ margin: "10px 25px" }}>
      <label style={{ fontSize: "12px" }}>{title}</label>
      <Number
        name="number"
        value={value}
        onChange={setValue}
        min={min}
        step={1000}
        max={max}
        disabled={disabled}

      />
    </Styles.DecisionSectionDivWrapper>
  );
};

export function UsertaskTextArea(body: any, setBody: (e: any) => void, placeholder: string = "Text") {
  return (
    <Styles.DecisionSectionDivWrapper style={{ marginTop: "10px" }}>
      <TextArea style={{ width: "100%", resize: "none" }}
        name="text"
        onChange={(e: any) => setBody(e.target.value)}
        defaultValue={body}
        placeholder={placeholder}
      />
    </Styles.DecisionSectionDivWrapper>
  );
}

export function UsertaskButtons(decision: any, complete: () => void, save: (() => void) | null = null) {
  return (
    <Styles.DecisionSectionDivWrapper style={{ display: "flex", justifyContent: "space-between" }}>
      <PrimaryProgressButton
        style={{ width: "140px" }}
        disabled={decision === null}
        onClick={complete}
      >
                Complete
      </PrimaryProgressButton>

      {save && (
        <PrimaryProgressButton
          style={{ width: "140px", backgroundColor: "#B8B8B8" }}
          onClick={save}
        >
          Save
        </PrimaryProgressButton>
      )}
    </Styles.DecisionSectionDivWrapper>
  );
}

export function renderMessage(message: any, index: number) {
  const date = new Date(message.timestamp);
  const day = date.toLocaleDateString("en-EN", { weekday: "long" });
  const dayNumber = date.toLocaleDateString("en-EN", { day: "2-digit" });
  const month = date.toLocaleDateString("en-EN", { month: "2-digit" });
  const year = date.toLocaleDateString("en-EN", { year: "numeric" });
  const time = date.toLocaleTimeString("en-EN", { hour: "2-digit", minute: "2-digit" });

  const isMail = message?.mail?.body !== undefined;
  const isCall = message?.call !== undefined;

  if (isCall) {
    return (
      <Styles.CallWrapper key={"Call-" + index}>
        Called to {message?.call?.to} on {day} {dayNumber}/{month}/{year} {time}
      </Styles.CallWrapper>
    );
  }

  return (
    <Styles.MessageWrapper key={"Message-" + index}>
      <Styles.MessageContainer>
        <Styles.MessageHeader>
          Sent {day} {dayNumber}/{month}/{year} <Styles.MessageHeaderBold>{time}</Styles.MessageHeaderBold> via {isMail ? "mail" : "SMS"}
        </Styles.MessageHeader>
        <Styles.MessageBody>
          {isMail ? message?.mail?.body : message?.sms?.message}
        </Styles.MessageBody>
      </Styles.MessageContainer>
      <Styles.MessageIcon>CW</Styles.MessageIcon>
    </Styles.MessageWrapper>
  );
}

type SliderProps = {
  min: number;
  max: number;
  value: number;
  onChange: (value: any) => void;
  disabled?: boolean;
};

export function Slider ({ min, max, value, onChange, disabled = false }: SliderProps) {
  return (

    <Styles.SliderContainer>
      <Styles.SliderLabel>Min {formatCurrency(min)}</Styles.SliderLabel>
      <Styles.SliderInput
        disabled={disabled}
        type="range"
        min={min}
        max={max}
        value={value}
        onChange={e => onChange(e.target.value)}
      />
      <Styles.SliderLabel>Max {formatCurrency(max)}</Styles.SliderLabel>
    </Styles.SliderContainer>

  );
}
