import { Country } from "../../types/common";
import { Spain } from "../Icons/Flags/Spain";
import { Sweden } from "../Icons/Flags/Sweden";
import { ProcessHeader } from "./styles";

export const OverviewHeader = (props: {
    country: Country;
    label: string;
}) => {
    const { country, label } = props;

    const getFlag = () => {
        switch (country) {
            case "ES":
                return <Spain />;
            case "SE":
                return <Sweden />;
            default:
                return null;
        }
    }

    return (
        <ProcessHeader>
            {getFlag()}
            {label}
        </ProcessHeader>)
};
