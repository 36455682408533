export const Sweden = () => {
  return (
    <svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_566_4347)">
        <mask id="mask0_566_4347" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="16">
          <path d="M21.7143 0H2.28571C1.02335 0 0 0.955126 0 2.13333V13.8667C0 15.0449 1.02335 16 2.28571 16H21.7143C22.9767 16 24 15.0449 24 13.8667V2.13333C24 0.955126 22.9767 0 21.7143 0Z" fill="white" />
        </mask>
        <g mask="url(#mask0_566_4347)">
          <path d="M21.7143 0H2.28571C1.02335 0 0 0.955126 0 2.13333V13.8667C0 15.0449 1.02335 16 2.28571 16H21.7143C22.9767 16 24 15.0449 24 13.8667V2.13333C24 0.955126 22.9767 0 21.7143 0Z" fill="#0275B0" />
          <path fillRule="evenodd" clipRule="evenodd" d="M6.85714 9.6H0V6.4H6.85714V0H10.2857V6.4H24V9.6H10.2857V16H6.85714V9.6Z" fill="#FED200" />
          <path d="M21.7151 0.533325H2.28655C1.33978 0.533325 0.572266 1.24967 0.572266 2.13333V13.8667C0.572266 14.7503 1.33978 15.4667 2.28655 15.4667H21.7151C22.6619 15.4667 23.4294 14.7503 23.4294 13.8667V2.13333C23.4294 1.24967 22.6619 0.533325 21.7151 0.533325Z" stroke="black" strokeOpacity="0.1" strokeWidth="1.14286" />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_566_4347">
          <rect width="24" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg >

  );
};
