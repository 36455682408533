import styled from "styled-components";
import { InfoSectionWrapper, FlexColumn } from "../styles";

export const InfoWrapper = styled(InfoSectionWrapper)`
    display: flex;
    justify-content: center;
    padding-top: 25px;
`;

export const DataWrapper = styled(FlexColumn)`
  height: fit-content;
  width: 70%;
  background-color: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0px 15px 40px rgba(184, 184, 184, 0.2), 0px 5px 10px rgba(184, 184, 184, 0.05);
  overflow: auto;
  max-height: 100%;

  /* hide scrollbar */
  &::-webkit-scrollbar {
    width: 0.2em;
    background-color: #F5F5F5;
  }

  &::-webkit-scrollbar-thumb {
  background-color: #F5F5F5;
  }

  &::-webkit-scrollbar-thumb:hover {
  background-color: #F5F5F5;
  }
`;

export const MediaWrapper = styled.div`
  max-width: 350px;
`;

export const ImageWrapper = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

export const VideoWrapper = styled.video`
  max-width: 100%;
  max-height: 100%;
`;