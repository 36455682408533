// Icon of a garbage bin to represent delete action
const DeleteIcon = (props: { onClick: () => void }) => (
  <div
    style={{ paddingRight: "24px", cursor: "pointer" }}
    onClick={props.onClick}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <g clipPath="url(#clip0_939_3707)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 0.5C5 0.223858 5.22386 0 5.5 0H10.5C10.7761 0 11 0.223858 11 0.5V3H15.5C15.7761 3 16 3.22386 16 3.5C16 3.77614 15.7761 4 15.5 4H0.5C0.223858 4 0 3.77614 0 3.5C0 3.22386 0.223858 3 0.5 3H5V0.5ZM10 1V3H6V1H10Z"
          fill="#E4251B"
        />
        <path
          d="M3 5.5C3 5.22386 2.77614 5 2.5 5C2.22386 5 2 5.22386 2 5.5V14C2 15.1041 2.89586 16 4 16H12C13.1041 16 14 15.1041 14 14V5.5C14 5.22386 13.7761 5 13.5 5C13.2239 5 13 5.22386 13 5.5V14C13 14.5519 12.5519 15 12 15H4C3.44814 15 3 14.5519 3 14V5.5Z"
          fill="#E4251B"
        />
        <path
          d="M8.5 7.5C8.5 7.22386 8.27614 7 8 7C7.72386 7 7.5 7.22386 7.5 7.5V12.5C7.5 12.7761 7.72386 13 8 13C8.27614 13 8.5 12.7761 8.5 12.5V7.5Z"
          fill="#E4251B"
        />
        <path
          d="M10.5 7C10.7761 7 11 7.22386 11 7.5V12.5C11 12.7761 10.7761 13 10.5 13C10.2239 13 10 12.7761 10 12.5V7.5C10 7.22386 10.2239 7 10.5 7Z"
          fill="#E4251B"
        />
        <path
          d="M6 7.5C6 7.22386 5.77614 7 5.5 7C5.22386 7 5 7.22386 5 7.5V12.5C5 12.7761 5.22386 13 5.5 13C5.77614 13 6 12.7761 6 12.5V7.5Z"
          fill="#E4251B"
        />
      </g>
      <defs>
        <clipPath id="clip0_939_3707">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </div>
);

export default DeleteIcon;
