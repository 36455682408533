import { type ReactNode, useCallback, useRef, useState, useEffect } from "react";
import { Container, TitleContainer, Title, Chevron, ContentWrapper, Content } from "./style";

export interface AccordionProps {
  title: string;
  children: ReactNode;
  startsExpanded?: boolean;
}

const Accordion = ({ title, children, startsExpanded }: AccordionProps): JSX.Element => {
  const [isExpanded, setExpand] = useState<boolean>(startsExpanded || false);
  const contentRef = useRef<HTMLDivElement>(null);
  const [contentHeight, setContentHeight] = useState<number>(0);

  useEffect(() => {
    if (!isExpanded) {
      setContentHeight(0);
    } else {
      if (contentRef.current) {
        setContentHeight(contentRef.current.scrollHeight);
      }
    }
  }, [isExpanded]);


  const handleExpandToggle = useCallback(() => {
    setExpand(!isExpanded);
  }, [isExpanded]);

  return (
    <Container>
      <TitleContainer isExpanded={isExpanded}>
        <Title onClick={handleExpandToggle}>
          {title}
          <Chevron direction={isExpanded ? "top" : "bottom"} />
        </Title>
      </TitleContainer>
      <ContentWrapper maxHeight={contentHeight}>
        <Content ref={contentRef}>{children}</Content>
      </ContentWrapper>
    </Container>
  );
};


export default Accordion;

