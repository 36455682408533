import React from "react";
import { Icons } from "@flow/icons";
import { Modal } from "@flow/modal";
import ManualApplicationReview from "../../tasks/manual-payout-approval";
import ManualPepReview from "../../tasks/manual-pep-review";
import VideoIdView from "../../tasks/video-id";
import { NormalInfoWrapper } from "../../tasks/styles";
import {
  ProcessWrapper,
  OverviewWrapper,
  RowsWrapper,
} from "../styles";
import type { Statistica } from "@flow";
import {
  OverviewSVGGreenWrapper,
  OverviewSVGBlueWrapper,
  OverviewLinks,
} from "./styles";
import { ApplicantInfoOverview } from "../../../components/Applicant/ApplicantInfoOverview";
import { OverviewRowCollection } from "../../../components/Rows/OverviewRowCollection";
import { OverviewIdRowCollection } from "../../../components/Rows/OverviewIdRowCollection";
import { OverviewHeader } from "../../../components/Header/OverviewHeader";
import QRCode from "react-qr-code";

export const CardOverview = (flow: any) => {
  //States used to view modals for KYC, transactions & video ID
  const [viewManualPayoutModal, setViewManualPayoutModal] =
    React.useState(false);
  const [viewPepReviewModal, setViewPepReviewModal] = React.useState(false);
  const [viewVideoIdModal, setViewVideoIdModal] = React.useState(false);
  //Variables used to display data
  const { data, flowId } = flow?.caseData || {};
  const { country, agentApiApplication: agentApi, offer } = data || {};
  const { applicant, agentId, externalApplicationId, appliedAmount } = agentApi || {};
  const { insuranceSelected, effectiveInterestRateWithInsurance, effectiveInterestRate: effIntRate, terms, finalLoanAmount } = offer || {};
  const { statistica } = data?.integrations || {};
  const qrData = data?.integrations?.signicat?.auth?.raw?.signicat?.headless?.idpData?.qrData || "";

  const effectiveInterestRate =
    insuranceSelected &&
      effectiveInterestRateWithInsurance
      ? effectiveInterestRateWithInsurance
      : effIntRate;

  const loanAmount =
    finalLoanAmount ||
    appliedAmount ||
    0;

  let rejectionRulesTriggeredByStatistica: any[] = [];
  if (statistica) {
    const typedStatistica: Statistica = statistica;
    rejectionRulesTriggeredByStatistica = Object.entries(typedStatistica)
      .flatMap(([, value]) =>
        value.policyResults
          ? Object.entries(value.policyResults)
            .filter(([, result]) => result === "Red")
            .map(([policy]) => policy)
          : [],
      )
      .flat();
  }

  //States for handling copying IDs
  const [copiedFlowID, setCopiedFlowID] = React.useState(false);
  const [copiedExternalID, setCopiedExternalID] = React.useState(false);

  //Copies IDs & resets copy states after being active for 1 second
  React.useEffect(() => {
    const timeout = setTimeout(() => {
      if (copiedExternalID) {
        navigator.clipboard.writeText(externalApplicationId);
        setCopiedExternalID(false);
      }
      if (copiedFlowID) {
        navigator.clipboard.writeText(flowId);
        setCopiedFlowID(false);
      }
    }, 1000);

    return () => clearTimeout(timeout);
  }, [copiedExternalID, copiedFlowID]);

  let formattedExpirationDate;
  const offerExpired = false;

  const applicationDetailsRows = [];
  const offerRows = [];

  const idConfig = [
    {
      title: "Flow ID",
      content: (
        <div
          onClick={() => setCopiedFlowID(true)}
          style={{ display: "flex", color: "#1B4DF5" }}
        >
          Copy Flow ID
          {copiedFlowID ? (
            <OverviewSVGGreenWrapper>
              <Icons.Check />
            </OverviewSVGGreenWrapper>
          ) : (
            <OverviewSVGBlueWrapper>
              <Icons.NewTab />
            </OverviewSVGBlueWrapper>
          )}
        </div>
      ),
    },
    {
      title: "External ID",
      content: (
        <div
          onClick={() => setCopiedExternalID(true)}
          style={{ display: "flex", color: "#1B4DF5" }}
        >
          Copy External ID
          {copiedExternalID ? (
            <OverviewSVGGreenWrapper>
              <Icons.Check />
            </OverviewSVGGreenWrapper>
          ) : (
            <OverviewSVGBlueWrapper>
              <Icons.NewTab />
            </OverviewSVGBlueWrapper>
          )}
        </div>
      ),
    },
  ];

  if (agentId)
    applicationDetailsRows.push({
      name: "Agent",
      value: agentId,
    });

  if (formattedExpirationDate)
    offerRows.push({
      name:
        data?.businessStatus === "loan-disbursed" || offerExpired
          ? "Offer expired"
          : "Offer valid until",
      value:
        data?.businessStatus === "loan-disbursed"
          ? "Disbursed"
          : formattedExpirationDate,
    });

  if (loanAmount)
    offerRows.push({
      name: data?.offer?.finalLoanAmount
        ? "Loan offered"
        : "Applied loan amount",
      value: `${new Intl.NumberFormat().format(loanAmount)}  €`,
    });

  if (effectiveInterestRate)
    offerRows.push({
      name: "Effective interest rate",
      value: `${effectiveInterestRate?.toString().replace(".", ",")}%`,
    });

  if (terms !== undefined)
    offerRows.push({
      name: "Terms",
      value: terms,
    });

  offerRows.push({
    name: "Offer given",
    value: data?.offer?.finalLoanAmount ? (
      <Icons.SuccessCircle />
    ) : flow?.caseData?.status === "active" ? (
      <Icons.ActiveCircle />
    ) : (
      <Icons.AbortedCircle />
    ),
  });

  if (
    rejectionRulesTriggeredByStatistica &&
    rejectionRulesTriggeredByStatistica.length > 0
  ) {
    const rules = rejectionRulesTriggeredByStatistica.map(
      (rule) => `• ${rule}`,
    );
    applicationDetailsRows.push({
      name: "Rejection reasons from Statistica",
      value: (
        <>
          {rules.map((el, index) => {
            return (
              <div
                key={index}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                {el}
              </div>
            );
          })}
        </>
      ),
    });
  }

  if (applicant?.psD2Data?.accounts && applicant?.psD2Data?.accounts.length > 0)
    applicationDetailsRows.push({
      name: "Transactions",
      value: (
        <OverviewLinks onClick={() => setViewManualPayoutModal(true)}>
          View transactions
        </OverviewLinks>
      ),
    });

  if (data?.integrations?.cm1)
    applicationDetailsRows.push({
      name: "KYC",
      value: (
        <OverviewLinks onClick={() => setViewPepReviewModal(true)}>
          View KYC
        </OverviewLinks>
      ),
    });

  if (data?.integrations?.electronicIdVideoId)
    applicationDetailsRows.push({
      name: "Video ID",
      value: (
        <OverviewLinks onClick={() => setViewVideoIdModal(true)}>
          View Video ID
        </OverviewLinks>
      ),
    });

  return (
    <OverviewWrapper>
      <OverviewHeader country={country} label="Credit card application" />
      <ProcessWrapper>
        <ApplicantInfoOverview applicant={applicant} />
        <RowsWrapper>
          <NormalInfoWrapper header>Application Details</NormalInfoWrapper>
          {OverviewIdRowCollection({ config: idConfig })}
          {OverviewRowCollection({ rows: applicationDetailsRows })}
          {viewManualPayoutModal ? (
            <Modal
              title="Manual Payout Approval"
              size="large"
              onClose={() => setViewManualPayoutModal(false)}
            >
              <ManualApplicationReview flow={flow} />
            </Modal>
          ) : null}
          {viewPepReviewModal ? (
            <Modal
              title="Manual AML / Pep review"
              size="large"
              onClose={() => setViewPepReviewModal(false)}
            >
              <ManualPepReview flow={flow} />
            </Modal>
          ) : null}
          {viewVideoIdModal ? (
            <Modal
              title="Video Id Data from electronic ID"
              size="large"
              onClose={() => setViewVideoIdModal(false)}
            >
              <VideoIdView
                videoId={data?.integrations?.electronicIdVideoId}
                signatureId={data?.integrations?.signatureId}
                flowId={data?.flowId}
              />
            </Modal>
          ) : null}
        </RowsWrapper>
        <RowsWrapper>
          <NormalInfoWrapper header>Offer Details</NormalInfoWrapper>
          {OverviewRowCollection({ rows: offerRows })}
        </RowsWrapper>
        {qrData && (
          <RowsWrapper>
            <NormalInfoWrapper header>Signicat</NormalInfoWrapper>
            <div style={{ padding: "7px 24px" }}>
              <QRCode size={100} value={qrData} />
            </div>
          </RowsWrapper>
        )}

      </ProcessWrapper>

    </OverviewWrapper>
  );
};

export default CardOverview;
