import { useState } from "react";
import { CommonUserTaskNew } from "../CommonUserTaskNew"
import * as Styles from "../styles"
import type { ComponentConfig } from "../types"
import { ApplicantDetailsLabel, ApplicantDetailsWrapper, FlexAlignCenter, LabelBold } from "./styles";
import { RadioButtonGroup } from "../../../components/Decision/RadioButtonGroup";
import { FormTextArea } from "../../../components/Input/FormTextArea";
import { ApplicantCollection } from "../../../components/Applicant/ApplicantCollection";

const ManualCheckFinal = (props: any) => {
  const formConfig: ComponentConfig[] = []
  const data = props.flow?.data;
  const applicant = data?.agentApiApplication?.applicant;
  const coApplicant = data?.agentApiApplication?.coApplicant;

  const [decision, setDecision] = useState(props?.task?.data?.decision ?? null);
  const [comment, setComment] = useState(props?.task?.data?.comment ?? null);

  const decisionErrorMessage = "Please select a decision.";
  const noErrorMessage = "";
  const [errorMessages, setErrorMessages] = useState<string>(noErrorMessage);

  const handleDecisionChange = (value: any) => {
    const decisionValue = (value === "true");
    setDecision(decisionValue)
  }

  const handleNoteChange = (value: any) => {
    setComment(value);
  }

  const handleSave = async () => {
    const formData = {
      decision,
      comment
    }
    props.save(
      formData,
      (error: any) => console.error({ error }),
      true
    );
  }

  const handleComplete = async () => {
    if (decision === null) {
      setErrorMessages(decisionErrorMessage);
      return;
    }
    
    const formData = {
      decision,
      comment
    }
    props.complete(
      formData,
      () => {},
      (e: any) => {
        setErrorMessages(e?.detail);
      },
    );
  }

  return (
    <>
      { errorMessages !== noErrorMessage &&
        <Styles.ErrorMessageBox>
          <FlexAlignCenter>
            <LabelBold>{errorMessages}</LabelBold>
          </FlexAlignCenter>
        </Styles.ErrorMessageBox>
      }
      <ApplicantDetailsWrapper>
        <ApplicantDetailsLabel>Applicant details</ApplicantDetailsLabel>
        <ApplicantCollection {...applicant} />
        {coApplicant && <ApplicantCollection {...coApplicant} />}
      </ApplicantDetailsWrapper>
      <RadioButtonGroup
        title="Decision"
        id="approved"
        radioButtonValue={decision}
        options={[
          { label: "Approve application", value: true },
          { label: "Reject application", value: false },
        ]}
        onChange={(e: any) => handleDecisionChange(e)}
      />
      <br />
      <FormTextArea
        label="Internal comment"
        name="internal-comment"
        onChange={(e: any) => handleNoteChange(e)}
        defaultValue={comment}
        placeholder="Other applicant information..."
      />
      <Styles.ContentFlexWrapper>
        <CommonUserTaskNew
          handleComplete={() => { handleComplete() }}
          handleSave={() => { handleSave() }}
          formConfig={formConfig}
          overrideMasonryColumns={3}
          disablePrimary={props.canComplete === false}
          disableSecondary={props.canSave === false}
        />
      </Styles.ContentFlexWrapper>
    </>
  );
}

export default ManualCheckFinal