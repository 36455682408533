import axios from "axios";
import { getAndCacheToken } from "./service.helper";

const initElectronicAPI = async () => {
  try {
    let electronicAPI;
    const { data } = await axios("/api/secrets");

    if (data) {
      const endpoint = await data.electronicIdEndpoint;
      electronicAPI = axios.create({
        baseURL: endpoint,
        timeout: 20000,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${data.electronicIdAccessToken}`,
        },
      });
    }

    return electronicAPI;
  } catch (error) {
    console.error("Error initializing Axios:", error);
    return null;
  }
};

export const getElectronicIdVideo = async (videoId: string) => {
  try {
    const electronicAPI: any = await getAndCacheToken("electronicIdAccessToken", initElectronicAPI);
    const video = await electronicAPI.get(`/videoid.file/${videoId}?=`, {
      responseType: "blob",
    });

    const videoUrl = URL.createObjectURL(video.data);
    return videoUrl;
  } catch (error) {
    console.error("Error getting electronic ID video:", error);
    return null;
  }
};

export const getElectronicIdData = async (videoId: string) => {
  try {
    const electronicAPI: any = await getAndCacheToken("electronicIdAccessToken", initElectronicAPI);
    const data = await electronicAPI.get(`/videoid/${videoId}`);

    return data;
  } catch (error) {
    console.error("Error getting electronic ID data:", error);
    return null;
  }
};

export const getTransactionsByFlowId = async (flowId: string, pageNumber: number) => {
  try {
    const electronicAPI: any = await getAndCacheToken("electronicIdAccessToken", initElectronicAPI);

    const data = await electronicAPI.get(`/logger/transactions?pageSize=10&pageNumber=${pageNumber}&externalReference=${flowId}`);
    return data;
  } catch (error) {
    console.error("Error getting electronic ID data:", error);
    return null;
  }
};
