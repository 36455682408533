import React, { Component } from "react";
import leaBankLogo from "./logo/lea-bank-square-white.jpg";

export default class CustomerIconLoginPage extends Component {
  render() {
    return (
      <div>
        <img src={leaBankLogo} alt="Lea Bank's logo" style={{ width: "100px", height: "100px", borderRadius: "30px" }} />
      </div>
    );
  }
}
