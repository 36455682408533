import { StyledErrorMessage } from "./styles";

const ErrorMessages = ({ errors }: { errors: string[] }) => (
  <StyledErrorMessage>
    {errors.map((message) => {
      return (
        <div key={message} style={{ color: "#9F1C15" }}>
          {message}
        </div>
      );
    })}
  </StyledErrorMessage>
);

export default ErrorMessages;
