import {
  CommonUserTaskContainer,
  ExpandContainer,
  FormSection,
} from "./styles";
import type { UserTaskProps } from "./types";
import { useState } from "react";
import { ChevronRight } from "../../components/Icons/ChevronRight";
import Cookies from "js-cookie";
import { ChevronLeft } from "../../components/Icons/ChevronLeft";
import { MasonryGrid } from "../../components/MasonryGrid/MasonryGrid";
import { FormButtons } from "../../components/Buttons/FormButtons";

/**
 * Renders a common user task component.
 * @component
 * @param {Object} props - The component props.
 * - infoConfig - The configuration for the info section.
 * - formConfig - The configuration for the form section.
 * - handleComplete - The function to handle the complete button click.
 * - handleSave - The function to handle the save button click.
 * - disableButtons - Indicates if the buttons should be disabled.
 * @returns {JSX.Element} The rendered component.
 */
export const CommonUserTaskNew = (props: UserTaskProps) => {
  const [expanded, setExpanded] = useState(
    Cookies.get("expanded") ? Cookies.get("expanded") === "true" : true
  );

  const handleExpandClick = (shouldExpand: boolean) => {
    setExpanded(shouldExpand);
    Cookies.set("expanded", String(shouldExpand));
  };

  const sortedInfoConfig = props.infoConfig
    ?.sort((a, b) => a.order - b.order)
    .filter((config) => !config.hide);
  const sortedFormConfig = props.formConfig
    ?.sort((a, b) => a.order - b.order)
    .filter((config) => !config.hide);

  return (
    <CommonUserTaskContainer>
      {sortedInfoConfig && (
        <>
          <ExpandContainer expanded={expanded}>
            {expanded ? (
              <ChevronRight onClick={() => handleExpandClick(false)} />
            ) : (
              <ChevronLeft onClick={() => handleExpandClick(true)} />
            )}
          </ExpandContainer>
          {expanded && (
            <MasonryGrid
              breakpointCols={{
                default: props.overrideMasonryColumns ?? 2,
                1250: 1,
              }}
              style={{ backgroundColor: "#F4F4F4" }}
              childrens={sortedInfoConfig}
            />
          )}
        </>
      )}
      <FormSection>
        {sortedFormConfig?.map((config, index) => (
          <div key={index}>{config.component}</div>
        ))}
        <FormButtons
          handleComplete={props.handleComplete}
          handleSave={props.handleSave}
          disabled={props.disableButtons}
          labelPrimary={props?.labelPrimary}
          labelSecondary={props?.labelSecondary}
          disablePrimary={props?.disablePrimary}
          disableSecondary={props?.disableSecondary}
        />
      </FormSection>
    </CommonUserTaskContainer>
  );
};
