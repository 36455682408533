export function getUserInfoFromSessionStorage() {
  const key = getStorageKey();
  const sessionStorageData = getSessionStorageData(key);
  const profile = sessionStorageData ? JSON.parse(sessionStorageData).profile : {};
  return {
    name: profile.name,
    username: profile.username,
    roles: getRolesFromProfile(sessionStorageData),
  };
}

function getRolesFromProfile(profile: string | null) {
  if (profile) {
    let decodedProfileData = JSON.parse(profile);
    let roles = decodedProfileData.profile.role;
    return roles;
  }
  return [];
}

function getSessionStorageData(key: string) {
  const profile = window.sessionStorage.getItem(key);
  return profile;
}

function getStorageKey() {
  for (let key in window.sessionStorage) {
    if (key.includes("quattro.stacc")) {
      return key;
    }
  }

  return "";
}
