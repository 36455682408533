import { LoanOverview } from "./loan";
import { CleanupOverview } from "./cleanup";
import type { Flow } from "@flow";
import CardOverview from "./card";

const ProcessOverview = (flow: Flow) => {
  if (
    flow.flowDefinitionId === "loan-es" ||
    (flow.flowDefinitionId === "common-process" &&
      flow.caseData.referenceId?.toLowerCase().includes("loan-se"))
  ) {
    return LoanOverview(flow);
  }

  if (
    (flow.flowDefinitionId === "common-process" &&
      flow.caseData.referenceId?.toLowerCase().includes("card-se")
    )
  ) {
    return CardOverview(flow);
  }

  if (
    flow.flowDefinitionId === "cleanup" ||
    flow.flowDefinitionId === "manual-cleanup"
  ) {
    return CleanupOverview(flow);
  }

  return `The "${flow.flowDefinitionId}" process is missing a dedicated component in src/components/overviewTab folder.`;
};

export default ProcessOverview;
