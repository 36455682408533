import styled from "styled-components";
import { getColorForEnvironment } from "../../views/tasks/styles";

export const ProcessHeader = styled.div`
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    padding: 16px;
    gap: 8px;
    background-color: ${getColorForEnvironment()};
    border-radius: 8px 8px 0px 0px;
    border-bottom: 1px solid #B8C4E0;
`;