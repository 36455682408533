export const Spain = () => {
  return (
    <svg
      width="24"
      height="16"
      viewBox="0 0 24 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_564_4337)">
        <mask
          id="mask0_564_4337"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="24"
          height="16"
        >
          <path
            d="M21.7143 0H2.28571C1.02335 0 0 0.955126 0 2.13333V13.8667C0 15.0449 1.02335 16 2.28571 16H21.7143C22.9767 16 24 15.0449 24 13.8667V2.13333C24 0.955126 22.9767 0 21.7143 0Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_564_4337)">
          <path
            d="M21.7143 0H2.28571C1.02335 0 0 0.955126 0 2.13333V13.8667C0 15.0449 1.02335 16 2.28571 16H21.7143C22.9767 16 24 15.0449 24 13.8667V2.13333C24 0.955126 22.9767 0 21.7143 0Z"
            fill="#CD0B20"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 4.26666H24V11.7333H0V4.26666Z"
            fill="#FFCB00"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.2866 6.63683V9.32483C10.2866 10.0715 9.51855 10.6688 8.57227 10.6688H6.28655C5.34255 10.6667 4.57227 10.0662 4.57227 9.3227V6.6347C4.57227 6.02457 5.08427 5.5147 5.78827 5.34937C6.00084 4.7947 6.65455 5.29177 7.42941 5.29177C8.20884 5.29177 8.85798 4.7979 9.07055 5.35043C9.77227 5.52003 10.2866 6.03097 10.2866 6.63683Z"
            fill="#C8B47C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.2849 7.46667H11.4277V10.6667H10.2849V7.46667ZM3.42773 7.46667H4.57059V10.6667H3.42773V7.46667Z"
            fill="#B5B5B5"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.2849 9.59998H11.4277V10.6666H10.2849V9.59998ZM3.42773 9.59998H4.57059V10.6666H3.42773V9.59998Z"
            fill="#165C96"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.2849 6.40002H11.4277V7.46669H10.2849V6.40002ZM3.42773 6.40002H4.57059V7.46669H3.42773V6.40002Z"
            fill="#A0790A"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.71484 6.40002H6.8577V8.00002H5.71484V6.40002ZM8.00056 8.53336H9.14342V10.1334H8.00056V8.53336Z"
            fill="#D20636"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 6.40002H9.14286V8.00002H8V6.40002Z"
            fill="#A18793"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.71484 8.53333H6.8577V10.1333H5.71484V8.53333Z"
            fill="#FFE100"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.8577 6.39998L5.71484 5.33331H9.14342L8.00056 6.39998H6.8577Z"
            fill="#B6161A"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.85742 4.26666H8.00028V5.33333H6.85742V4.26666Z"
            fill="#AC9300"
          />
          <path
            d="M21.7151 0.533325H2.28655C1.33978 0.533325 0.572266 1.24967 0.572266 2.13333V13.8667C0.572266 14.7503 1.33978 15.4667 2.28655 15.4667H21.7151C22.6619 15.4667 23.4294 14.7503 23.4294 13.8667V2.13333C23.4294 1.24967 22.6619 0.533325 21.7151 0.533325Z"
            stroke="black"
            strokeOpacity="0.1"
            strokeWidth="1.14286"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_564_4337">
          <rect width="24" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
