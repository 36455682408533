import type { IconProps } from "./types";

export const ChevronLeft = (props: IconProps) => {
  return (
    <svg
      onClick={props.onClick}
      style={{ cursor: "pointer" }}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.14645 7.85355C3.95118 7.65829 3.95118 7.34171 4.14645 7.14645L10.1464 1.14645C10.3417 0.951185 10.6583 0.951185 10.8536 1.14645C11.0488 1.34171 11.0488 1.65829 10.8536 1.85355L5.20711 7.5L10.8536 13.1464C11.0488 13.3417 11.0488 13.6583 10.8536 13.8536C10.6583 14.0488 10.3417 14.0488 10.1464 13.8536L4.14645 7.85355Z"
        fill="#1E1E1E"
      />
    </svg>
  );
};
